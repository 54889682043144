import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import GuacamoleKeyboard from "./guacamoleKeyboard";

import styles from "./BrowserSandbox.module.css";
import { useSelector } from "react-redux";
import { selectUserRole, UserRole } from "redux/userRedux";
import {
  selectAudioProviderOnly,
  selectCanControl,
  selectVideoConferencing,
} from "redux/settingsRedux";
import clsx from "clsx";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import playIcon from "assets/icons/play.svg";
import { logUnexpectedError } from "utils/errorUtils";
import { calculateFitDimensions } from "utils/sizingUtils";
import ActivityNavigationHeader from "pages/Space/components/ActivityNavigationHeader/ActivityNavigationHeader";
import {
  selectCurrentResourceId,
  selectCurrentRoomItemId,
} from "redux/spaceNavigationRedux";
import { useSnapshot } from "pages/Space/hooks/useSnapshot";
import { selectClientFileOpen } from "redux/clientManagementRedux";
import {
  useEmitDesktopControlEvent,
  useRoomEventListener,
} from "pages/Space/components/ConnectionsContext/RoomConnectionContext";
import {
  AudioTrack,
  useMaybeRoomContext,
  VideoTrack,
} from "@livekit/components-react";
import { useBrowserSandboxTracks } from "pages/Space/components/ConnectionsContext/WebRTCContext";
import { useBoxRef } from "hooks/useBoxRef";

const hasMacOSKbd = () => {
  // TODO: don't use deprecated navigator.platform
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
};

const KeyTable = {
  XK_ISO_Level3_Shift: 0xfe03, // AltGr
  XK_Mode_switch: 0xff7e, // Character set switch
  XK_Control_L: 0xffe3, // Left control
  XK_Control_R: 0xffe4, // Right control
  XK_Meta_L: 0xffe7, // Left meta
  XK_Meta_R: 0xffe8, // Right meta
  XK_Alt_L: 0xffe9, // Left alt
  XK_Alt_R: 0xffea, // Right alt
  XK_Super_L: 0xffeb, // Left super
  XK_Super_R: 0xffec, // Right super
};
const keyMap = (key: number): number => {
  // Alt behaves more like AltGraph on macOS, so shuffle the
  // keys around a bit to make things more sane for the remote
  // server. This method is used by noVNC, RealVNC and TigerVNC
  // (and possibly others).
  if (hasMacOSKbd()) {
    switch (key) {
      case KeyTable.XK_Meta_L:
        key = KeyTable.XK_Control_L;
        break;
      case KeyTable.XK_Super_L:
        key = KeyTable.XK_Alt_L;
        break;
      case KeyTable.XK_Super_R:
        key = KeyTable.XK_Super_L;
        break;
      case KeyTable.XK_Alt_L:
        key = KeyTable.XK_Mode_switch;
        break;
      case KeyTable.XK_Alt_R:
        key = KeyTable.XK_ISO_Level3_Shift;
        break;
    }
  }
  return key;
};

const clearTimeoutIfSet = (
  timeoutRef: React.MutableRefObject<NodeJS.Timeout | undefined>
) => {
  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
  }
};

type BrowserSandboxProps = {
  url: string;
};

const BrowserSandbox = ({ url }: BrowserSandboxProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const wheelThrottleRef = useRef(false);
  // @ts-ignore
  const keyboard = useRef(new GuacamoleKeyboard());
  const [browserSandboxLoadingState, setBrowserSandboxLoadingState] = useState<
    "loading" | "ready" | "error"
  >("loading");
  const [forceMute, setForceMute] = useState(true);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const playingTimeout = useRef<NodeJS.Timeout>();
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);

  const audioElementRef = useRef<HTMLAudioElement>(null);

  const { videoTrack, audioTrack } = useBrowserSandboxTracks();

  const userRole = useSelector(selectUserRole);
  const clientCanControl = useSelector(selectCanControl);
  const audioProviderOnly = useSelector(selectAudioProviderOnly);
  const videoConferencing = useSelector(selectVideoConferencing);
  const resourceId = useSelector(selectCurrentResourceId);
  const roomItemId = useSelector(selectCurrentRoomItemId);
  const isClientFileOpen = useSelector(selectClientFileOpen);

  if (userRole === null) {
    throw new Error("unexpectedly missing userRole");
  }

  const userCanControl =
    (userRole === UserRole.CLIENT && clientCanControl) ||
    userRole === UserRole.THERAPIST;

  const userCanControlRef = useRef(userCanControl); // for use in keyUp and keyDown callbackes
  userCanControlRef.current = userCanControl;

  useSnapshot(videoRef.current);

  const emitDesktopControlEvent = useEmitDesktopControlEvent();

  useEffect(() => {
    if (!roomItemId || !resourceId) {
      logUnexpectedError(
        "roomItemId or resourceId is not set when loading browser sandbox"
      );
      return;
    }

    if (url) {
      console.log("emitting openBrowserActivity", {
        url,
        roomItemId,
        resourceId,
      });
      emitDesktopControlEvent("openBrowserActivity", {
        url,
        roomItemId,
        resourceId,
      });
      setBrowserSandboxLoadingState("loading");
    }

    return () => {
      console.log("emitting closeBrowserActivity");
      emitDesktopControlEvent("closeBrowserActivity", {});
    };
  }, [url]);

  useRoomEventListener("browserActivityOpened", () => {
    console.log("received browserActivityOpened");
    setBrowserSandboxLoadingState("ready");
  });
  useRoomEventListener("openBrowserActivityError", () => {
    setBrowserSandboxLoadingState("error");
    alert(
      "Something unexpected happened when opening the URL. Please, try closing and opening the activity again."
    );
  });

  useEffect(() => {
    /* Initialize Guacamole Keyboard */
    keyboard.current.onkeydown = (key: number) => {
      if (document.activeElement !== document.body) {
        return true;
      }
      if (userCanControlRef.current) {
        emitDesktopControlEvent("keyDown", {
          key: keyMap(key),
          agent: userRole,
        });
      }
      return false;
    };
    keyboard.current.onkeyup = (key: number) => {
      if (userCanControlRef.current) {
        emitDesktopControlEvent("keyUp", { key: keyMap(key), agent: userRole });
      }
    };
    const cleanupKeyboard = keyboard.current.listenTo(document);

    return () => {
      cleanupKeyboard();
      clearTimeoutIfSet(playingTimeout);
    };
  }, []);

  useLayoutEffect(() => {
    videoRef.current?.addEventListener("resize", resizeVideo);
    window.addEventListener("resize", resizeVideo);

    return () => {
      videoRef.current?.removeEventListener("resize", resizeVideo);
      window.removeEventListener("resize", resizeVideo);
    };
  }, []);

  const play = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      try {
        videoRef.current.play().catch((err) => {
          logUnexpectedError(err);
          // Show the play button if user interaction is required to play the video
          setShowPlayButton(true);
        });
        setShowPlayButton(false);
      } catch (err) {
        logUnexpectedError(err);
        // Show the play button if user interaction is required to play the video
        setShowPlayButton(true);
      }
    }
  };

  const resizeVideo = () => {
    const parentHeight = videoContainerRef.current?.offsetHeight;
    const parentWidth = videoContainerRef.current?.offsetWidth;
    const rawHeight = videoRef.current?.videoHeight;
    const rawWidth = videoRef.current?.videoWidth;
    if (!parentHeight || !parentWidth) {
      return;
    }
    const { fitHeight, fitWidth } = calculateFitDimensions(
      parentHeight,
      parentWidth,
      // If the media stream is not yet loaded, use defaults to avoid rendering a 0x0 video.
      // Otherwise, LiveKit's adaptive stream will stop the
      // video track briefly and cause some flashes
      rawHeight ?? 720,
      rawWidth ?? 1280,
      null
    );
    setVideoHeight(fitHeight);
    setVideoWidth(fitWidth);
  };

  useEffect(() => {
    resizeVideo();
  }, [isClientFileOpen]);

  const getPointerOffsetPercentages = ({
    offsetX,
    offsetY,
  }: {
    offsetX: number;
    offsetY: number;
  }) => {
    if (
      !videoRef.current ||
      videoRef.current.offsetWidth === 0 ||
      videoRef.current.offsetHeight === 0
    ) {
      return null;
    }
    return {
      xp: offsetX / videoRef.current.offsetWidth,
      yp: offsetY / videoRef.current.offsetHeight,
    };
  };

  // TODO: decrease message size, potentially by using bit arrays like in neko https://github.com/m1k1o/neko/blob/fed6ddbd4e9f2eff487f8a7d5194a3d7e4f4a10b/client/src/neko/base.ts#L130
  const sendMousePosition = (offsets: { offsetX: number; offsetY: number }) => {
    const offsetPercentages = getPointerOffsetPercentages(offsets);
    if (offsetPercentages) {
      emitDesktopControlEvent("mouseMove", {
        ...offsetPercentages,
        agent: userRole,
      });
    }
  };

  const onPointerMove = (event: React.PointerEvent<HTMLVideoElement>) => {
    sendMousePosition(event.nativeEvent);
  };

  const WHEEL_LINE_HEIGHT = 19;

  const onWheel = (event: React.WheelEvent<HTMLVideoElement>) => {
    if (!videoRef.current) return;

    let x = event.deltaX;
    let y = event.deltaY;

    // Pixel units unless it's non-zero.
    // Note that if deltamode is line or page won't matter since we aren't
    // sending the mouse wheel delta to the server anyway.
    // The difference between pixel and line can be important however since
    // we have a threshold that can be smaller than the line height.
    if (event.deltaMode !== 0) {
      x *= WHEEL_LINE_HEIGHT;
      y *= WHEEL_LINE_HEIGHT;
    }

    // TODO: handle scroll direction settings (scroll_invert, scroll sensitivity, wheelThrottle?)
    // if (this.scroll_invert) {
    //   x = x * -1
    //   y = y * -1
    // }

    const SCROLL_SENSITIVITY = 1; // Make higher to make the scroll move more

    x = Math.min(Math.max(x, -SCROLL_SENSITIVITY), SCROLL_SENSITIVITY);
    y = Math.min(Math.max(y, -SCROLL_SENSITIVITY), SCROLL_SENSITIVITY);

    sendMousePosition(event.nativeEvent);

    if (!wheelThrottleRef.current) {
      wheelThrottleRef.current = true;
      emitDesktopControlEvent("scroll", { x, y, agent: userRole });
      window.setTimeout(() => {
        wheelThrottleRef.current = false;
      }, 100);
    }
  };

  const onPointerDown = (event: React.PointerEvent<HTMLVideoElement>) => {
    const offsetPercentages = getPointerOffsetPercentages(event.nativeEvent);
    if (offsetPercentages) {
      emitDesktopControlEvent("buttonDown", {
        key: event.button + 1,
        ...offsetPercentages,
        agent: userRole,
      });
    }
  };

  const onPointerUp = (event: React.PointerEvent<HTMLVideoElement>) => {
    const offsetPercentages = getPointerOffsetPercentages(event.nativeEvent);
    if (offsetPercentages) {
      emitDesktopControlEvent("buttonUp", {
        key: event.button + 1,
        ...offsetPercentages,
        agent: userRole,
      });
    }
  };

  const showLoading =
    browserSandboxLoadingState === "loading" ||
    browserSandboxLoadingState === "error" ||
    !videoTrack ||
    !videoTrack.publication.track;

  // Delaying the unmute in 2s, because there is a chance of briefly playing another activity audio
  useEffect(() => {
    if (showLoading) {
      setForceMute(true);
    } else {
      const timeoutId = setTimeout(() => {
        setForceMute(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [showLoading]);

  const muteAudio =
    (userRole === UserRole.CLIENT && audioProviderOnly && !videoConferencing) ||
    forceMute;

  const onReset = () => {
    emitDesktopControlEvent("resetBrowserActivity", {});
    // Show loading screen, disable keyboard inputs and mute video for a second
    userCanControlRef.current = false;
    setBrowserSandboxLoadingState("loading");
  };

  useRoomEventListener("browserActivityReset", () => {
    console.log("received browserActivityReset");
    userCanControlRef.current = true;
    setBrowserSandboxLoadingState("ready");
  });
  useRoomEventListener("resetBrowserActivityError", () => {
    userCanControlRef.current = true;
    setBrowserSandboxLoadingState("error");
    alert(
      "Something unexpected happened when refreshing the page. Please, try closing and opening the activity again."
    );
  });
  console.log("videoTrack", videoTrack);
  console.log("audioTrack", audioTrack);

  // Clearing the srcObject of the audio element before unmounting to fix an issue
  // with the audio still playing after the component is unmounted.
  // This needs to be a useLayoutEffect to run before LiveKit's unmount effects runs
  useLayoutEffect(() => {
    return () => {
      if (audioElementRef.current) {
        audioElementRef.current.srcObject = null;
      }
    };
  }, []);

  const room = useMaybeRoomContext();
  const roomRef = useBoxRef(room);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      let debugDiv = document.getElementById("debugDiv");
      if (!debugDiv) {
        debugDiv = document.createElement("div");
        debugDiv.id = "debugDiv";
        debugDiv.style.position = "absolute";
        debugDiv.style.right = "0";
        debugDiv.style.bottom = "0";
        debugDiv.style.zIndex = "9999";
        debugDiv.style.backgroundColor = "#ffaaffdd";
        debugDiv.style.padding = "10px";
        document.body.appendChild(debugDiv);
      }
      if (!videoRef.current) {
        debugDiv.innerText = "No videoRef";
        return;
      }
      let text = "";
      const mediaStream = videoRef.current.srcObject as MediaStream;
      text = `readyState: ${videoRef.current.readyState}\n`;
      text += `srcObject: ${mediaStream}\n`;
      text += `currentTime: ${videoRef.current.currentTime}\n`;
      text += `paused: ${videoRef.current.paused ? "true" : "false"}\n`;
      text += `ended: ${videoRef.current.ended ? "true" : "false"}\n`;
      text += `videoTrackEnabled: ${
        mediaStream?.getVideoTracks()[0]?.enabled
      }\n`;
      text += `totalVideoFrames: ${
        videoRef.current.getVideoPlaybackQuality().totalVideoFrames
      }\n`;
      text += `droppedVideoFrames: ${
        videoRef.current.getVideoPlaybackQuality().droppedVideoFrames
      }\n`;
      text += `corruptedVideoFrames: ${
        videoRef.current.getVideoPlaybackQuality().corruptedVideoFrames
      }\n`;

      if (roomRef.current) {
        const stats =
          await roomRef.current.engine?.pcManager?.subscriber?.getStats();

        if (stats) {
          const videostats = [...stats.values()].filter(
            (stat) => stat.type === "inbound-rtp" && stat.kind === "video"
          );
          for (const videostat of videostats) {
            const {
              framesDecoded,
              framesReceived,
              bytesReceived,
              frameHeight,
              trackIdentifier,
              framesDropped,
            } = videostat;
            text += `video: ${trackIdentifier}\n`;
            text += `framesDecoded: ${framesDecoded}\n`;
            text += `framesReceived: ${framesReceived}\n`;
            text += `framesDropped: ${framesDropped}\n`;
            text += `bytesReceived: ${bytesReceived}\n`;
            text += `frameHeight: ${frameHeight}\n`;
          }
          const codecstats = [...stats.values()].filter(
            (stat) => stat.type === "codec"
          );
          for (const codecstat of codecstats) {
            const { mimeType, clockRate, payloadType } = codecstat;
            text += `codec: ${mimeType}\n`;
            text += `clockRate: ${clockRate}\n`;
            text += `payloadType: ${payloadType}\n`;
          }
        }
      }
      debugDiv.innerText = text;
    }, 50);
    return () => {
      const debugDiv = document.getElementById("debugDiv");
      if (debugDiv) {
        document.body.removeChild(debugDiv);
      }
      const debugLogDiv = document.getElementById("debugLogDiv");
      if (debugLogDiv) {
        document.body.removeChild(debugLogDiv);
      }
      clearInterval(intervalId);
    };
  }, []);

  const debugLog = (message: string) => {
    let debugLogDiv = document.getElementById("debugLogDiv");
    if (!debugLogDiv) {
      debugLogDiv = document.createElement("div");
      debugLogDiv.id = "debugLogDiv";
      debugLogDiv.style.position = "absolute";
      debugLogDiv.style.left = "0";
      debugLogDiv.style.bottom = "0";
      debugLogDiv.style.zIndex = "9999";
      debugLogDiv.style.backgroundColor = "#aaffffdd";
      debugLogDiv.style.padding = "10px";
      debugLogDiv.onclick = () => {
        if (videoRef.current) {
          videoRef.current.muted = true;
          videoRef.current.autoplay = true;
          videoRef.current
            .play()
            .then(() => debugLog("Successfully played"))
            .catch((err) => debugLog("Error playing: " + err));
        }
      };
      document.body.appendChild(debugLogDiv);
    }

    debugLogDiv.innerText += message + "\n";
  };

  return (
    <div data-testid="browser-sandbox" className={styles.container}>
      {/* Always rendering the spinner on the background, and using z-index to bring it to front
          when we want to show it. This way, when the video is ready, but waiting for the frames to
          arrive, it will be transparent and the spinner will be visible. */}
      <div
        className={clsx(styles.loadingContainer, {
          [styles.loadingContainerHidden]: !showLoading,
        })}
      >
        <LoadingAnimation />
      </div>
      <div
        className={clsx(styles.navOuterContainer, {
          [styles.outerNoControl]: !userCanControl,
        })}
      >
        <div
          className={clsx(styles.navInnerContainer, {
            [styles.innerNoControl]: !userCanControl,
          })}
        >
          <ActivityNavigationHeader isExternal={true} onReset={onReset} />
        </div>
      </div>
      <div
        className={clsx(styles.videoOuterContainer, {
          [styles.outerNoControl]: !userCanControl && !showPlayButton,
        })}
      >
        <div
          ref={videoContainerRef}
          className={clsx(styles.videoContainer, {
            [styles.innerNoControl]: !userCanControl,
          })}
        >
          {videoTrack && (
            <VideoTrack
              trackRef={videoTrack}
              className={styles.video}
              ref={videoRef}
              style={{
                width: videoWidth,
                height: videoHeight,
              }}
              playsInline
              onPointerMove={onPointerMove}
              onWheel={onWheel}
              onPointerDown={onPointerDown}
              onPointerUp={onPointerUp}
              onContextMenu={(e) => e.preventDefault()}
              disablePictureInPicture
              onLoadedMetadata={() => {
                debugLog("onLoadMetadata");
                resizeVideo();
              }}
              onPlaying={() => {
                debugLog("Video is playing");
              }}
              onPause={() => {
                debugLog("Video is paused");
              }}
              onEnded={() => {
                debugLog("Video has ended");
              }}
              onStalled={() => {
                debugLog("Video is stalled");
              }}
              onSuspend={() => {
                debugLog("Video is suspended");
              }}
              onError={(e) => {
                debugLog("Video encountered an error: " + e);
              }}
              onWaiting={() => {
                debugLog("Video is waiting");
              }}
            />
          )}
          {/* There is a bug where LiveKit emits audio briefly when the AudioTrack is mounted with muted=true
           * To circumvent that, we will only mount the AudioTrack when not muted
           */}
          {audioTrack && !muteAudio && (
            <AudioTrack trackRef={audioTrack} ref={audioElementRef} />
          )}
        </div>
        {showPlayButton && !showLoading ? (
          <img
            className={styles.playButton}
            src={playIcon}
            alt={"Start"}
            onClick={play}
          />
        ) : null}
      </div>
    </div>
  );
};

export default BrowserSandbox;
