import React from "react";

import styles from "./Modal.module.css";
import { ReactComponent as XIcon } from "assets/icons/x.svg";
import Overlay from "components/Overlay/Overlay";

type ModalProps = {
  closeModal?: () => void;
  className?: string;
  children?: React.ReactNode;
};

// TODO: make the overlay and modal siblings and remove ev.stopPropagation
const Modal = ({ closeModal, className, children }: ModalProps) => (
  <Overlay closeOverlay={closeModal} className={className}>
    <div className={styles.modal} onClick={(ev) => ev.stopPropagation()}>
      {closeModal && (
        <span className={styles.close}>
          <XIcon className={styles.xIcon} onClick={closeModal} />
        </span>
      )}
      <div className={styles.content}>{children}</div>
    </div>
  </Overlay>
);

export default Modal;
