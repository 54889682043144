import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import styles from "./UpgradeModal.module.css";
import { useDispatch } from "react-redux";
import { setShowUpgradeModal } from "redux/spaceNavigationRedux";
import Modal from "components/Modal/Modal";
import { backendRequest, sleep } from "utils/backendRequest";
import { ReactComponent as BackIcon } from "assets/icons/back_arrow.svg";
import { useSubscriptionContext } from "components/SubscriptionContext/SubsciptionContext";
import Toggle from "components/Toggle/Toggle";
import CenteredLoadingDiv from "components/CenteredLoadingDiv/CenteredLoadingDiv";

let stripePromise: Promise<Stripe | null>;
if (process.env.REACT_APP_STRIPE_API_KEY) {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
}

const STRIPE_ENV =
  process.env.REACT_APP_ENVIRONMENT === "production" ? "production" : "staging";
const PRICING_PAGE_URL = `https://teleo.space/pricing-embedded?env=${STRIPE_ENV}`;

type SubscriptionPeriod = "monthly" | "yearly";

type CheckoutSession = Record<
  SubscriptionPeriod,
  { stripePriceId: string; stripeCouponId?: string }
>;

// TODO: Handle edge situation where user pressed back button or closes the window after clicking "subscribe"
const UpgradeModal = () => {
  const dispatch = useDispatch();
  const { fetchSubscription } = useSubscriptionContext();
  const stripePriceIdToClientSecretMap = useRef<Record<string, string>>({});
  const [checkoutSession, setCheckoutSession] = useState<CheckoutSession>();
  const [subscriptionPeriod, setSubscriptionPeriod] =
    useState<SubscriptionPeriod>("monthly");
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [purchaseComplete, setPurchaseComplete] = useState<boolean>(false);

  const fetchClientSecret = async () => {
    setClientSecret(null);
    if (!checkoutSession) {
      return;
    }

    const currentCheckoutSession = checkoutSession[subscriptionPeriod];

    const existingClientSecret =
      stripePriceIdToClientSecretMap.current[
        currentCheckoutSession.stripePriceId
      ];
    if (existingClientSecret) {
      // Stripe's embedded checkout can only be initialized with a single client secret.
      // To re-load the checkout iframe, we must wait for stripe checkout to unmount before setting the new client secret.
      await sleep(500);
      setClientSecret(existingClientSecret);
    }

    // Create a Checkout Session
    const checkoutSessionResponse = await backendRequest({
      path: "/billing-create-checkout-session",
      options: {
        method: "POST",
        body: JSON.stringify(currentCheckoutSession),
        headers: {
          "Content-Type": "application/json",
        },
      },
    });

    const checkoutSessionData = await checkoutSessionResponse.json();

    stripePriceIdToClientSecretMap.current[
      currentCheckoutSession.stripePriceId
    ] = checkoutSessionData.clientSecret;

    setClientSecret(checkoutSessionData.clientSecret);
  };

  useEffect(() => {
    fetchClientSecret();
  }, [checkoutSession, subscriptionPeriod]);

  const onComplete = useCallback(() => {
    setPurchaseComplete(true);
    fetchSubscription();
  }, []);

  const closeModal = () => {
    dispatch(setShowUpgradeModal(false));
  };

  const onIFrameMessageSelected = (messageEvent: MessageEvent<any>) => {
    if (messageEvent.data?.stripeData && messageEvent.data?.selectedPeriod) {
      setCheckoutSession(messageEvent.data.stripeData);
      setSubscriptionPeriod(messageEvent.data.selectedPeriod);
    }
  };

  const handleBack = () => {
    setCheckoutSession(undefined);
  };

  useEffect(() => {
    window.addEventListener("message", onIFrameMessageSelected);
    return () => {
      window.removeEventListener("message", onIFrameMessageSelected);
    };
  }, []);

  const isYearlySubscription = subscriptionPeriod === "yearly";

  const renderHeader = () => {
    if (!checkoutSession) {
      return <span>Select plan</span>;
    }

    if (purchaseComplete) {
      return <span>You may now close this window.</span>;
    }

    return (
      <>
        <span>Monthly</span>
        <Toggle
          isOn={isYearlySubscription}
          onToggle={() =>
            setSubscriptionPeriod(isYearlySubscription ? "monthly" : "yearly")
          }
          className={styles.toggle}
        />
        <span>Yearly</span>
      </>
    );
  };

  const renderBody = () => {
    if (!checkoutSession) {
      return (
        <iframe src={PRICING_PAGE_URL} className={styles.pricingPageIFrame} />
      );
    }

    if (clientSecret) {
      return (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret, onComplete }}
        >
          <EmbeddedCheckout className={styles.embeddedCheckout} />
        </EmbeddedCheckoutProvider>
      );
    }

    return <CenteredLoadingDiv isLight />;
  };

  return (
    <Modal closeModal={closeModal} className={styles.modal}>
      <div className={styles.heading}>
        {clientSecret && !purchaseComplete && (
          <div className={styles.backContainer}>
            <BackIcon className={styles.backArrowIcon} onClick={handleBack} />
            Back
          </div>
        )}
        {renderHeader()}
      </div>
      <div
        className={clsx(styles.bodyWrapper, {
          [styles.yellowBackground]: !!checkoutSession,
        })}
      >
        {renderBody()}
      </div>
    </Modal>
  );
};

export default UpgradeModal;
