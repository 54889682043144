import React from "react";

import styles from "./LoadingAnimation.module.css";
import clsx from "clsx";

type LoadingAnimationProps = {
  isSmall?: boolean;
  isLight?: boolean;
};

const LoadingAnimation = ({ isSmall, isLight }: LoadingAnimationProps) => {
  return (
    <div
      className={clsx(styles.loadingRing, {
        [styles.small]: isSmall,
        [styles.light]: isLight,
      })}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingAnimation;
