import { useDispatch, useSelector } from "react-redux";
import {
  selectBrowserSandboxUrl,
  selectCurrentPage,
  selectCurrentResourceId,
  selectCurrentRoomItemId,
  selectCustomColors,
  selectHasLaunchedActivityFromFullScreenVideo,
  selectIsFullScreenVideo,
  selectShowRoomCustomizationActivityModal,
  selectWhiteboardBackground,
  setBrowserSandboxUrl,
  setCurrentPage,
  setCurrentResourceId,
  setCurrentRoomItemId,
  setCustomColors,
  setHasLaunchedActivityFromFullScreenVideo,
  setHasReceivedSyncState,
  setIsFullScreenVideo,
  setShowRoomCustomizationActivityModal,
  setWhiteboardBackground,
} from "redux/spaceNavigationRedux";
import {
  selectAudioProviderOnly,
  selectClientCanControl,
  selectIsMuted,
  selectIsVideoOff,
  selectIsViewportLocked,
  selectIsWidescreenVideoEnabled,
  selectPeerIdWithControl,
  selectVideoConferencing,
  setAudioProviderOnly,
  setClientCanControl,
  setIsPeerMuted,
  setIsPeerVideoOff,
  setIsViewportLocked,
  setIsWidescreenVideoEnabled,
  setPeerIdWithControl,
  setVideoConferencing,
} from "redux/settingsRedux";

import {
  selectAlbumPages,
  selectCurrentAlbumPage,
  selectImgKeysToURL,
  selectViewingSnapshot,
  setAlbumPages,
  setCurrentAlbumPage,
  setImgKeysToURL,
  setViewingSnapshot,
} from "redux/clientAlbumRedux";
import {
  selectCurrentClient,
  setCurrentClient,
} from "redux/clientManagementRedux";
import { store } from "redux/reduxStore";
import {
  selectEditItemId,
  selectIsActivityBankOpen,
  setIsProviderEditing,
} from "redux/editRoomNavigationRedux";
import { useTeleoEvent } from "../components/ConnectionsContext/teleoPeerEventUtils";
import {
  getLocalPeerId,
  useOnParticipantDisconnect,
  useOnWebRTCConnect,
} from "../components/ConnectionsContext/WebRTCContext";
import { useIsTherapist } from "./useIsTherapist";
import { useCallback } from "react";
import { useEmitLocalUserMediaError } from "./video/useSyncMediaError";

export const useSyncState = () => {
  const dispatch = useDispatch();
  const isTherapist = useIsTherapist();
  const currentClient = useSelector(selectCurrentClient);
  const clientCanControl = useSelector(selectClientCanControl);
  const peerIdWithControl = useSelector(selectPeerIdWithControl);
  const currentPage = useSelector(selectCurrentPage);
  const whiteboardBackground = useSelector(selectWhiteboardBackground);
  const customColors = useSelector(selectCustomColors);
  const browserSandboxUrl = useSelector(selectBrowserSandboxUrl);
  const showRoomCustomizationActivityModal = useSelector(
    selectShowRoomCustomizationActivityModal
  );
  const audioProviderOnly = useSelector(selectAudioProviderOnly);
  const videoConferencing = useSelector(selectVideoConferencing);
  const isFullScreenVideo = useSelector(selectIsFullScreenVideo);
  const isWidescreenVideoEnabled = useSelector(selectIsWidescreenVideoEnabled);
  const hasLaunchedActivityFromFullScreenVideo = useSelector(
    selectHasLaunchedActivityFromFullScreenVideo
  );
  const isMuted = useSelector(selectIsMuted);
  const isVideoOff = useSelector(selectIsVideoOff);
  const roomItemId = useSelector(selectCurrentRoomItemId);
  const resourceId = useSelector(selectCurrentResourceId);

  const currentAlbumPage = useSelector(selectCurrentAlbumPage);
  const albumPages = useSelector(selectAlbumPages);
  const imgKeysToURL = useSelector(selectImgKeysToURL);
  const viewingSnapshot = useSelector(selectViewingSnapshot);

  const isViewportLocked = useSelector(selectIsViewportLocked);

  const isActivityBankOpen = useSelector(selectIsActivityBankOpen);
  const editItemId = useSelector(selectEditItemId);

  const isProviderEditing = isActivityBankOpen || !!editItemId;

  const emitIsPeerMuted = useTeleoEvent("peer-muted");
  const emitPeerVideoOff = useTeleoEvent("peer-video-off");
  const emitLocalUserMediaError = useEmitLocalUserMediaError();

  const emitSync = useTeleoEvent("sync-state", (payload) => {
    console.log("received sync state", payload);
    const peerId = payload.senderId;
    const state = store.getState();
    const currentIsMuted = selectIsMuted(state);
    const currentIsVideoOff = selectIsVideoOff(state);
    if (!isTherapist) {
      dispatch(setCurrentClient(payload.currentClient));
      dispatch(setClientCanControl(payload.clientCanControl));
      dispatch(setPeerIdWithControl(payload.peerIdWithControl));
      dispatch(setAudioProviderOnly(payload.audioProviderOnly));
      dispatch(setIsFullScreenVideo(payload.isFullScreenVideo));
      dispatch(setIsWidescreenVideoEnabled(payload.isWidescreenVideoEnabled));
      dispatch(setVideoConferencing(payload.videoConferencing));
      dispatch(
        setHasLaunchedActivityFromFullScreenVideo(
          payload.hasLaunchedActivityFromFullScreenVideo
        )
      );
      if (peerId) {
        dispatch(setIsPeerMuted({ peerId, isMuted: payload.isMuted ?? false }));
        dispatch(
          setIsPeerVideoOff({ peerId, isVideoOff: payload.isVideoOff ?? false })
        );
      }
      const navigationState = payload.navigationState;
      dispatch(setWhiteboardBackground(navigationState.whiteboardBackground));
      dispatch(setCustomColors(navigationState.customColors));
      dispatch(setBrowserSandboxUrl(navigationState.browserSandboxUrl));
      dispatch(setCurrentPage(navigationState.currentPage));
      dispatch(setCurrentRoomItemId(navigationState.currentRoomItemId));
      dispatch(
        setShowRoomCustomizationActivityModal(
          navigationState.showRoomCustomizationActivityModal
        )
      );
      dispatch(setCurrentResourceId(navigationState.resourceId));
      dispatch(setCurrentAlbumPage(payload.currentAlbumPage));
      dispatch(setAlbumPages(payload.albumPages));
      dispatch(setImgKeysToURL(payload.imgKeysToURL));
      dispatch(setViewingSnapshot(payload.viewingSnapshot));
      // Resend the client's mute and video off state to the provider when they reconnect
      emitIsPeerMuted({
        isPeerMuted: currentIsMuted ?? false,
        targetPeerId: getLocalPeerId(),
      });
      emitPeerVideoOff({
        isPeerVideoOff: currentIsVideoOff ?? false,
        targetPeerId: getLocalPeerId(),
      });
      // Also resend the client's media errors to the provider
      emitLocalUserMediaError();
      dispatch(setIsViewportLocked(payload.isViewportLocked));
      dispatch(setIsProviderEditing(payload.isProviderEditing));
      dispatch(setHasReceivedSyncState(true));
    }
  });

  const maybeSendSyncState = useCallback(
    (peerId?: string) => {
      if (isTherapist) {
        console.log("emitting sync state");
        if (!peerIdWithControl && peerId) {
          dispatch(setPeerIdWithControl(peerId));
        }
        emitSync({
          senderId: getLocalPeerId(),
          currentClient,
          clientCanControl,
          peerIdWithControl: peerIdWithControl ?? peerId,
          audioProviderOnly,
          videoConferencing,
          isFullScreenVideo,
          isWidescreenVideoEnabled,
          hasLaunchedActivityFromFullScreenVideo,
          isMuted,
          isVideoOff,
          navigationState: {
            currentPage,
            currentRoomItemId: roomItemId,
            resourceId: resourceId,
            whiteboardBackground,
            customColors,
            browserSandboxUrl,
            showRoomCustomizationActivityModal,
          },
          currentAlbumPage,
          albumPages,
          imgKeysToURL,
          viewingSnapshot,
          isViewportLocked,
          isProviderEditing,
        });
      }
    },
    [
      isTherapist,
      peerIdWithControl,
      currentClient,
      clientCanControl,
      audioProviderOnly,
      videoConferencing,
      isFullScreenVideo,
      isWidescreenVideoEnabled,
      hasLaunchedActivityFromFullScreenVideo,
      isMuted,
      isVideoOff,
      currentPage,
      roomItemId,
      whiteboardBackground,
      customColors,
      browserSandboxUrl,
      showRoomCustomizationActivityModal,
      currentAlbumPage,
      albumPages,
      imgKeysToURL,
      viewingSnapshot,
      isViewportLocked,
      isProviderEditing,
    ]
  );

  const emitUserJoined = useTeleoEvent("user-joined", (payload) => {
    maybeSendSyncState(payload.peerId);
  });

  useOnWebRTCConnect((remoteParticipants) => {
    console.log("on webrtc connect", remoteParticipants);
    const firstClient = remoteParticipants.find(
      (remoteParticipant) => remoteParticipant.attributes.role === "client"
    );
    // Waiting to send first data channel messages because it fails
    // in multi-region setups. Specially in Safari.
    setTimeout(() => {
      emitUserJoined({ peerId: getLocalPeerId() });
      maybeSendSyncState(firstClient?.identity);
    }, 1000);
  });

  const emitPeerIdWithControl = useTeleoEvent("peer-can-control-cursor");

  useOnParticipantDisconnect(
    (disconnectedParticipant, remainingParticipants) => {
      if (!isTherapist) {
        return;
      }
      if (disconnectedParticipant.identity === peerIdWithControl) {
        // first client that isn't the one disconnected
        const newPeerIdWithControl = remainingParticipants.find(
          (participant) =>
            participant.attributes.role === "client" &&
            participant.identity !== disconnectedParticipant.identity
        )?.identity;

        dispatch(setPeerIdWithControl(newPeerIdWithControl));

        if (newPeerIdWithControl) {
          emitPeerIdWithControl({
            targetPeerId: newPeerIdWithControl,
          });
        }
      }
    }
  );
};
