import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import styles from "./BillingCounter.module.css";
import { PlanTag } from "./PlanTag";

import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as AddActivityIcon } from "assets/icons/add_activity.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as InstructionalIcon } from "assets/icons/instructional.svg";
import { ReactComponent as HelpOutlineIcon } from "assets/icons/help_outline.svg";
import { ReactComponent as InstructionalFilledIcon } from "assets/icons/instructional_filled.svg";

import { setShowUpgradeModal } from "redux/spaceNavigationRedux";
import {
  LOW_USAGE_PERCENT_THRESHOLD,
  useSubscriptionContext,
} from "components/SubscriptionContext/SubsciptionContext";
import Tooltip from "components/Tooltip/Tooltip";
import { backendRequest } from "utils/backendRequest";
import { selectOrganizationId } from "redux/userRedux";

const TRIAL_TOOLTIP = (
  <>
    Teleo offers a 10-session free trial of its Unlimited plan, with no time
    limit.
    <br />
    Afterward, your account automatically converts to the Free plan.
  </>
);
const SESSION_TOOLTIP = (
  <>
    Sessions must be at least 16 minutes to count towards a Teleo session.
    <br />
    For example, short practice sessions using an incognito tab won&apos;t
    count.
  </>
);
const CUSTOM_ACTIVITY_TOOLTIP =
  "Custom activities may be added or deleted in the Activity Bank.";
const CLIENT_RECORD_TOOLTIP = (
  <>
    Client records provide a HIPAA compliant way to capture and store
    information
    <br />
    from sessions, including snapshots of client&apos;s work and past session
    durations.
    <br />
    Clinicians get unlimited client records on Plus and Unlimited plans.
    <br />
    Client records may be deleted to make space on the Free plan.
  </>
);

const metricFormatter = new Intl.ListFormat("en", {
  style: "long",
  type: "conjunction",
});

type ProgressBarProps = {
  current: number;
  max: number;
};

const ProgressBar = ({ current, max }: ProgressBarProps) => {
  const percentage = Math.ceil((current / max) * 100);
  let barColor = styles.progressGreen;

  if (percentage >= 100) {
    barColor = styles.progressRed;
  } else if (percentage >= LOW_USAGE_PERCENT_THRESHOLD) {
    barColor = styles.progressOrange;
  }

  return (
    <div className={styles.progressContainer}>
      <div
        className={clsx(styles.progressBar, barColor)}
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

type BillingCounterProps = {
  containerClassName?: string;
};

const BillingCounter = ({ containerClassName }: BillingCounterProps) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(selectOrganizationId);
  const {
    planId,
    plan,
    stripePlanId,
    stripePlan,
    metricUsage,
    lowUsageMetrics,
    maxUsageMetrics,
    currentPeriodEnd,
    shouldEnforceUsageLimits,
    hidePaywall,
  } = useSubscriptionContext();

  const planRenewalDayCountdown = useMemo(() => {
    if (
      !currentPeriodEnd ||
      planId === "FREE_TRIAL" ||
      planId === "UNLIMITED"
    ) {
      return 0;
    }
    const today = new Date();
    const timeDiff = currentPeriodEnd - today.getTime() / 1000;
    return Math.ceil(timeDiff / (3600 * 24));
  }, [currentPeriodEnd]);

  const handleManagePlan = async () => {
    hidePaywall();
    if (planId === "FREE" || (planId === "FREE_TRIAL" && !stripePlanId)) {
      dispatch(setShowUpgradeModal(true));
      return;
    }

    // Avoid popup blockers by opening the new tab on a user gesture
    const newTab = window.open("about:blank");
    const response = await backendRequest({
      path: "/billing-create-customer-portal-session",
      options: { method: "POST" },
    });
    const { sessionUrl } = await response.json();

    if (newTab) {
      newTab.location.href = sessionUrl;
    } else {
      alert("Popup blocked! Please allow popups for this site.");
    }
  };

  const { name, maxClientCount, maxCustomActivityCount, maxSessionCount } =
    plan ?? {};

  const { sessionCount, customActivityCount, clientCount } = metricUsage;

  const usageWarning = useMemo(() => {
    if (planId === "FREE_TRIAL") {
      return `Your trial will convert to the ${
        stripePlan?.name || "Free"
      } plan after 10 sessions.`;
    }

    const usageLimitWarning = !shouldEnforceUsageLimits
      ? " Limits enforced on 3/1."
      : "";
    const warningSuffix = `Upgrade to unlock more.${usageLimitWarning}`;
    if (maxUsageMetrics.length > 0) {
      return `You are out of ${metricFormatter.format(
        maxUsageMetrics
      )}. ${warningSuffix}`;
    }

    if (lowUsageMetrics.length > 0) {
      return `You are low on ${metricFormatter.format(
        lowUsageMetrics
      )}. ${warningSuffix}`;
    }

    return "";
  }, [planId, stripePlanId, maxUsageMetrics, lowUsageMetrics]);

  if (!planId || !plan || !name || organizationId) {
    return null;
  }

  return (
    <div className={clsx(styles.planContainer, containerClassName)}>
      <div className={styles.planDetails}>
        <div className={styles.planHeader}>
          <div className={styles.planTitle}>
            <span className={styles.planName}>
              {`Teleo ${name} ${planId === "FREE_TRIAL" ? "Trial" : "Plan"}`}
              {planId === "FREE_TRIAL" && (
                <Tooltip text={TRIAL_TOOLTIP} hoverDelay={100}>
                  <HelpOutlineIcon className={styles.helpIcon} />
                </Tooltip>
              )}
            </span>
            <PlanTag planId={planId} planName={name} />
          </div>
          {usageWarning && (
            <div
              className={clsx(styles.usageWarning, {
                [styles.usageLow]: lowUsageMetrics.length > 0,
                [styles.usageReachedMax]: maxUsageMetrics.length > 0,
              })}
            >
              <InstructionalFilledIcon />
              <span>
                {usageWarning}{" "}
                {planId === "FREE_TRIAL" && !stripePlanId && (
                  <>
                    {"See all plans "}
                    <button
                      className={styles.managePlan}
                      onClick={handleManagePlan}
                    >
                      here
                    </button>
                    .
                  </>
                )}
              </span>
            </div>
          )}
        </div>

        {/* Sessions */}
        <div className={styles.usageSection}>
          <div className={styles.usageHeader}>
            <span className={styles.usageHeaderLabel}>
              <ClockIcon className={styles.usageHeaderIcon} />
              Sessions
              <Tooltip text={SESSION_TOOLTIP} hoverDelay={100}>
                <InstructionalIcon className={styles.infoIcon} />
              </Tooltip>
            </span>
            <span className={styles.usageHeaderValue}>
              {planRenewalDayCountdown ? (
                <div
                  className={styles.planReset}
                >{`Resets in ${planRenewalDayCountdown} days ·`}</div>
              ) : null}
              {maxSessionCount
                ? `${sessionCount}/${maxSessionCount}`
                : "Unlimited"}
            </span>
          </div>
          {maxSessionCount && sessionCount !== undefined && (
            <ProgressBar current={sessionCount} max={maxSessionCount} />
          )}
        </div>

        {/* Custom Activities */}
        <div className={styles.usageSection}>
          <div className={styles.usageHeader}>
            <span className={styles.usageHeaderLabel}>
              <AddActivityIcon className={styles.usageHeaderIcon} />
              Custom Activities
              <Tooltip text={CUSTOM_ACTIVITY_TOOLTIP} hoverDelay={100}>
                <InstructionalIcon className={styles.infoIcon} />
              </Tooltip>
            </span>
            <span className={styles.usageHeaderValue}>
              {maxCustomActivityCount
                ? `${customActivityCount}/${maxCustomActivityCount}`
                : "Unlimited"}
            </span>
          </div>
          {maxCustomActivityCount && customActivityCount !== undefined && (
            <ProgressBar
              current={customActivityCount}
              max={maxCustomActivityCount}
            />
          )}
        </div>

        {/* Client Records */}
        <div className={styles.usageSection}>
          <div className={styles.usageHeader}>
            <span className={styles.usageHeaderLabel}>
              <PersonIcon className={styles.usageHeaderIcon} />
              Client Records
              <Tooltip text={CLIENT_RECORD_TOOLTIP} hoverDelay={100}>
                <InstructionalIcon className={styles.infoIcon} />
              </Tooltip>
            </span>
            <span className={styles.usageHeaderValue}>
              {maxClientCount
                ? `${clientCount}/${maxClientCount}`
                : "Unlimited"}
            </span>
          </div>
          {maxClientCount && clientCount !== undefined && (
            <ProgressBar current={clientCount} max={maxClientCount} />
          )}
        </div>
      </div>

      <button className={styles.managePlan} onClick={handleManagePlan}>
        {!!stripePlanId ? "Manage" : "Upgrade"} plan
      </button>
    </div>
  );
};

export default BillingCounter;
