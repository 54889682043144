import { useEffect } from "react";
import { logUnexpectedError } from "utils/errorUtils";

export const useLiveKitTest = () => {
  async function testLiveKitConnectivity(liveKitUrl: string) {
    try {
      const response = await fetch(liveKitUrl);

      if (!response.ok) {
        logUnexpectedError(
          `LiveKit endpoint responded with non-OK status: ${response.status}`
        );
      }
    } catch (error) {
      // Log human readable and JS error to maximize error reporting
      logUnexpectedError(`Failed to access LiveKit: ${error}`);
      logUnexpectedError(error);
    }
  }

  useEffect(() => {
    testLiveKitConnectivity("https://teleo-2rkw03ph.livekit.cloud/");
  }, []);
};
