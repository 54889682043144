import React, { MouseEventHandler, useEffect } from "react";

import { ReactComponent as SettingsCameraIcon } from "assets/icons/settings_camera.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { VideoButton } from "./VideoButton";
import styles from "./MediaMenuButton.module.css";
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import {
  selectIsNoiseSuppressionEnabled,
  selectIsWidescreenVideoEnabled,
  setIsNoiseSuppressionEnabled,
  setIsWidescreenVideoEnabled,
} from "redux/settingsRedux";
import { useLogMediaEvent, useTrackEvent } from "utils/metricsUtils";
import { useTeleoEvent } from "../../ConnectionsContext/teleoPeerEventUtils";
import { useLocalUserMediaSelection } from "../../ConnectionsContext/useLocalUserMedia";

type MediaMenuButtonProps = {
  className: string;
};

export const MediaMenuButton = ({ className }: MediaMenuButtonProps) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const isWidescreenVideoEnabled = useSelector(selectIsWidescreenVideoEnabled);
  const isNoiseSuppressionEnabled = useSelector(
    selectIsNoiseSuppressionEnabled
  );
  const {
    audioInputDevices,
    videoInputDevices,
    activeAudioInputDeviceId,
    activeVideoInputDeviceId,
    selectActiveAudioInputDevice,
    selectActiveVideoInputDevice,
  } = useLocalUserMediaSelection();

  const emitIsWideScreenVideoEnabled = useTeleoEvent(
    "is-widescreen-video-enabled"
  );

  const dispatch = useDispatch();

  const logMediaEvent = useLogMediaEvent();
  const { trackEvent } = useTrackEvent();

  const { refs, floatingStyles } = useFloating({
    open: isMenuOpen,
    placement: "right-start",
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    middleware: [offset({ mainAxis: 5, crossAxis: 0 }), flip(), shift()],
  });

  const toggleMenu: MouseEventHandler = (event) => {
    setIsMenuOpen(!isMenuOpen);
    event.stopPropagation();
  };

  const stopProppagation: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  const toggleWidescreenVideo = () => {
    const newValue = !isWidescreenVideoEnabled;
    dispatch(setIsWidescreenVideoEnabled(newValue));
    emitIsWideScreenVideoEnabled({ isWidescreenVideoEnabled: newValue });
    trackEvent("Video chat - toggle widescreen video", {
      "Is Widescreen Video Enabled": newValue,
    });
  };

  const toggleNoiseSupression = () => {
    const newValue = !(isNoiseSuppressionEnabled ?? true);
    dispatch(setIsNoiseSuppressionEnabled(newValue));
    logMediaEvent(
      newValue ? "ENABLE_NOISE_SUPPRESSION" : "DISABLE_NOISE_SUPPRESSION"
    );
  };

  const selectAudioDevice = (device: MediaDeviceInfo) => {
    selectActiveAudioInputDevice(device);
    logMediaEvent("SELECT_AUDIO_INPUT");
  };

  const selectVideoDevice = (device: MediaDeviceInfo) => {
    selectActiveVideoInputDevice(device);
    logMediaEvent("SELECT_VIDEO_INPUT");
  };

  useEffect(() => {
    const globalClickHandler = (event: MouseEvent) => {
      if (!refs.floating.current?.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener("click", globalClickHandler);
    return () => {
      window.removeEventListener("click", globalClickHandler);
    };
  }, []);

  return (
    <div className={className}>
      <VideoButton
        id="MediaMenuButton"
        onClick={toggleMenu}
        tooltipText={"Options"}
        forceVisible={isMenuOpen}
        icon={SettingsCameraIcon}
        ref={refs.setReference}
      />
      {isMenuOpen && (
        <div
          className={styles.menuContainer}
          ref={refs.setFloating}
          style={floatingStyles}
          onClick={stopProppagation}
          onPointerDown={stopProppagation}
          onPointerUp={stopProppagation}
        >
          <div className={styles.sectionHeader}>Select a Microphone</div>
          {audioInputDevices?.length > 0 ? (
            audioInputDevices.map((device, index) => (
              <div
                className={styles.menuItem}
                key={device.deviceId}
                onClick={() => selectAudioDevice(device)}
              >
                <span className={styles.checkmark}>
                  {((!activeAudioInputDeviceId && index === 0) ||
                    activeAudioInputDeviceId === device.deviceId) && (
                    <CheckIcon />
                  )}
                </span>
                <div className={styles.menuItemLabel}>{device.label}</div>
              </div>
            ))
          ) : (
            <div className={clsx(styles.menuItem, styles.disabled)}>
              No Microphone device Found
            </div>
          )}
          <hr className={styles.sectionSeparator} />
          <div className={styles.sectionHeader}>Select a Camera</div>
          {videoInputDevices?.length > 0 ? (
            videoInputDevices.map((device, index) => (
              <div
                className={styles.menuItem}
                key={device.deviceId}
                onClick={() => selectVideoDevice(device)}
              >
                <span className={styles.checkmark}>
                  {((!activeVideoInputDeviceId && index === 0) ||
                    activeVideoInputDeviceId === device.deviceId) && (
                    <CheckIcon />
                  )}
                </span>
                <div className={styles.menuItemLabel}>{device.label}</div>
              </div>
            ))
          ) : (
            <div className={clsx(styles.menuItem, styles.disabled)}>
              No Camera device Found
            </div>
          )}
          <hr className={styles.sectionSeparator} />
          <div className={styles.menuItem} onClick={toggleWidescreenVideo}>
            <span className={styles.checkmark}>
              {!!isWidescreenVideoEnabled && <CheckIcon />}
            </span>
            <div className={styles.menuItemLabel}>Widescreen Talk Mode</div>
          </div>
          <div className={styles.menuItem} onClick={toggleNoiseSupression}>
            <span className={styles.checkmark}>
              {isNoiseSuppressionEnabled ?? true ? <CheckIcon /> : null}
            </span>
            <div className={styles.menuItemLabel}>Noise Suppression</div>
          </div>
        </div>
      )}
    </div>
  );
};
