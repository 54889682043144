import {
  type TrackProcessor,
  type Track,
  type VideoProcessorOptions,
  Room,
} from "livekit-client";
import { calculateFitDimensions } from "utils/sizingUtils";
import { drawBokehEffect } from "./blurringUtils";
// TODO: See if we can remove @tensorflow/tfjs-backend-webgl, @tensorflow/tfjs-core, or @tensorflow/tfjs-converter packages.
//  Removing @tensorflow/tfjs-core or @tensorflow/tfjs-converter currently gives build errors.
//  Removing @tensorflow/tfjs-backend-webgl seemed to work fine, but https://blog.tensorflow.org/2022/01/body-segmentation.html
//  says it is required, so we didn't want to risk removing it.
import "@tensorflow/tfjs-backend-webgl";
import * as bodySegmentation from "@tensorflow-models/body-segmentation";

// Helpful documentation: https://blog.tensorflow.org/2022/01/body-segmentation.html
//
// Decision log:
// - Used the Selfie Segmentation model instead of BodyPix because BodyPix had worse accuracy and no better performance in testing
// - Used MediaPipe runtime rather than TFJS runtime because it loads faster and was more performant on desktop safari in testing, although TFJS was slightly more performant on ipad

const CONFIG = {
  modelType: "general", // Possible options: landscape or general; landscape is a smaller model but doesn't seem to significantly improve performance
  resolutionMaxSize: 480, // Compress the image so that its longest dimension (height or width) is this size; mainly useful for ensuring a consistent "blurriness", but also improves performance a bit
  foregroundThreshold: 0.7, // How confident the model has to be to consider a pixel as a person (0-1)
  backgroundBlur: 15, // How blurry to make the background (1-20)
  edgeBlur: 3, // How blurry to make the edge between the person and the background (1-20)
  FPSLimit: 20, // Limit the blurred video FPS to this number; useful for performance
} as const;

const MINIMUM_FPS_INTERVAL = 1000 / CONFIG.FPSLimit;

export default class TeleoBackgroundBlurProcessor
  implements TrackProcessor<Track.Kind.Video, VideoProcessorOptions>
{
  name: string = "teleo-background-blur";

  // The blurred output stream track that will be used by LiveKit
  processedTrack?: MediaStreamTrack;

  // Video element created by LiveKit where we add the requestVideoFrameCallback
  // to start rendering a new frame
  private inputVideoElement?: HTMLVideoElement;

  // Canvas used to draw the final output
  // Every draw to this canvas is a new frame generated in the processedTrack
  private outputCanvas: HTMLCanvasElement;
  private outputCanvasCtx: CanvasRenderingContext2D | null;

  // canvas used to generate image data for segmentation
  // and then to draw the bokeh effect (blurred background)
  private tmpInputCanvas: HTMLCanvasElement;
  private tmpInputCanvasCtx: CanvasRenderingContext2D | null;

  // Used to cancel the requested animation frame in the video element
  private requestVideoFrameCallbackId?: number;
  // Used to skip rendering a frame if we are still processing the previous one
  private isRenderFrameRunning = false;
  // Used to skip rendering frames if above the limit FPS
  private lastProcessedFrameTimestamp = 0;

  private segmenter?: bodySegmentation.BodySegmenter;

  private onError?: (error: Error) => void;

  constructor(onError: (error: Error) => void) {
    this.outputCanvas = document.createElement("canvas");
    this.outputCanvasCtx = this.outputCanvas.getContext("2d");

    this.tmpInputCanvas = document.createElement("canvas");
    this.tmpInputCanvasCtx = this.tmpInputCanvas.getContext("2d");

    this.onError = onError;
  }

  private async loadSegmenterOnce() {
    if (this.segmenter) {
      return;
    }
    console.log("initializing segmenter loading");

    this.segmenter = await bodySegmentation.createSegmenter(
      bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation,
      {
        runtime: "mediapipe",
        modelType: CONFIG.modelType,
        // TODO: Consider hosting these static files ourselves if helpful in the future
        solutionPath:
          "https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation",
      }
    );
    console.log("completed segmenter loading");
  }

  private handleVideoResize = () => {
    console.log("handleVideoResize");
    if (!this.inputVideoElement) {
      console.log("unexpected no video element on play");
      return;
    }
    const videoWidth = this.inputVideoElement.videoWidth;
    const videoHeight = this.inputVideoElement.videoHeight;

    this.inputVideoElement.width = videoWidth;
    this.inputVideoElement.height = videoHeight;

    // Here is where we limit the blurred video resolution
    const { fitHeight: height, fitWidth: width } = calculateFitDimensions(
      CONFIG.resolutionMaxSize,
      CONFIG.resolutionMaxSize,
      videoHeight,
      videoWidth,
      null
    );

    this.outputCanvas.width = width;
    this.outputCanvas.height = height;
    this.tmpInputCanvas.width = width;
    this.tmpInputCanvas.height = height;
  };

  async init(opts: VideoProcessorOptions): Promise<void> {
    // Initialize the processor with the given options
    try {
      console.log("Initializing blur processor with options", opts);
      const outputStream = this.outputCanvas.captureStream();
      this.processedTrack = outputStream.getVideoTracks()[0];

      if (!(opts.element instanceof HTMLVideoElement)) {
        throw new Error("Element must be an HTMLVideoElement");
      }

      this.inputVideoElement = opts.element;
      this.inputVideoElement.addEventListener("resize", this.handleVideoResize);

      this.cancelAnimationFrameIfExists();
      this.requestVideoFrameCallbackId =
        this.inputVideoElement.requestVideoFrameCallback(this.renderFrame);
      this.isRenderFrameRunning = false;

      await this.loadSegmenterOnce();
      console.log("Initialized blur processor");
    } catch (e: any) {
      this.onError?.(e);
      throw e;
    }
  }

  async restart(opts: VideoProcessorOptions): Promise<void> {
    // Restart the processor with the given options
    console.log(`Restarting with options: ${JSON.stringify(opts)}`);
    await this.destroy();
    await this.init(opts);
  }

  async destroy(): Promise<void> {
    // Clean up any resources used by the processor
    // Only cleaning resources that will be re-created in init()
    console.log(`Destroying processor: ${this.name}`);
    this.processedTrack = undefined;
    this.inputVideoElement?.removeEventListener(
      "resize",
      this.handleVideoResize
    );
    this.cancelAnimationFrameIfExists();
    this.inputVideoElement = undefined;
  }

  async onPublish(room: Room): Promise<void> {
    // Handle the event when the track is published to a room
    console.log(`Track published to room: ${room.name}`);
  }

  async onUnpublish(): Promise<void> {
    // Handle the event when the track is unpublished from a room
    console.log(`Track unpublished`);
  }

  cancelAnimationFrameIfExists() {
    if (this.requestVideoFrameCallbackId) {
      this.inputVideoElement?.cancelVideoFrameCallback(
        this.requestVideoFrameCallbackId
      );
      this.requestVideoFrameCallbackId = undefined;
    }
  }

  renderFrame = async (timestamp: number) => {
    try {
      // if there is a previous frame being rendered, we skip this one
      if (this.isRenderFrameRunning) {
        return;
      }

      this.isRenderFrameRunning = true;
      const finishRender = () => {
        this.isRenderFrameRunning = false;
        // !this.inputVideoElement means the processor has been destroyed
        // so we stop the render loop
        if (!this.inputVideoElement) {
          return;
        }
        // Cancel any duplicated animation request that has been started in the meantime
        this.cancelAnimationFrameIfExists();
        this.requestVideoFrameCallbackId =
          this.inputVideoElement?.requestVideoFrameCallback(this.renderFrame);
      };

      // If anything is missing, we skip this frame until everything is ready.
      if (
        !this.inputVideoElement ||
        !this.inputVideoElement.width ||
        !this.inputVideoElement.height ||
        !this.segmenter ||
        !this.outputCanvasCtx ||
        !this.tmpInputCanvasCtx
      ) {
        finishRender();
        return;
      }

      // Skip rendering frames above the limit FPS
      if (timestamp - this.lastProcessedFrameTimestamp < MINIMUM_FPS_INTERVAL) {
        finishRender();
        return;
      }
      this.lastProcessedFrameTimestamp = timestamp;

      this.tmpInputCanvasCtx.drawImage(
        this.inputVideoElement,
        0,
        0,
        this.tmpInputCanvas.width,
        this.tmpInputCanvas.height
      );
      const segmentationInput = this.tmpInputCanvasCtx.getImageData(
        0,
        0,
        this.tmpInputCanvas.width,
        this.tmpInputCanvas.height
      );

      if (segmentationInput === undefined) {
        throw new Error(
          "Undefined segmentation input- stopping render of blurred background"
        );
      }

      const segmentation = await this.segmenter.segmentPeople(
        segmentationInput,
        {
          flipHorizontal: false,
        }
      );

      // this draws in the canvas, which will output a new frame in the video stream
      await drawBokehEffect(
        this.tmpInputCanvas,
        segmentationInput,
        segmentation,
        CONFIG.foregroundThreshold,
        CONFIG.backgroundBlur,
        CONFIG.edgeBlur
      );

      this.outputCanvasCtx.drawImage(
        this.tmpInputCanvas,
        0,
        0,
        this.tmpInputCanvas.width,
        this.tmpInputCanvas.height
      );

      finishRender();
    } catch (e: any) {
      this.onError?.(e);
      this.isRenderFrameRunning = false;
    }
  };
}
