import { useApolloClient } from "@apollo/client";
import { logUnexpectedError } from "./errorUtils";
import { selectMeetingID } from "redux/spaceNavigationRedux";
import {
  admitAdditionalParticipant,
  removeWaitingRoomAlertClient,
  selectClientHasJoinedRoom,
  selectCurrentClient,
  selectIncomingClient,
  triggerClientAdmission,
} from "redux/clientManagementRedux";

import { useDispatch, useSelector } from "react-redux";
import { useLogClientManagementEvent } from "./metricsUtils";
import { selectSessionEHRSystem } from "redux/ehrSystemRedux";
import { backendRequest } from "utils/backendRequest";
import { usePeersParticipants } from "pages/Space/components/ConnectionsContext/connectionsUtils";
import mixpanel from "mixpanel-browser";

const MAX_CLIENT_COUNT = 2;

export const useRefetchWaitingRoom = () => {
  const client = useApolloClient();

  const refetchWaitingRoom = async () => {
    client.refetchQueries({
      include: ["GetWaitingRoomParticipants", "GetControlBarData"],
    });
  };
  return refetchWaitingRoom;
};

export const useAdmitClient = () => {
  return useUpdateWaitingRoomOnServer("admit");
};

export const useDismissClient = () => {
  return useUpdateWaitingRoomOnServer("dismiss");
};

const useUpdateWaitingRoomOnServer = (operation: "admit" | "dismiss") => {
  const meetingID = useSelector(selectMeetingID);
  const incomingClient = useSelector(selectIncomingClient);
  const dispatch = useDispatch();
  const refetchWaitingRoom = useRefetchWaitingRoom();

  return async ({
    clientId,
    admitPeerAs = "differentClient",
  }: {
    clientId?: string;
    admitPeerAs?: "additionalParticipant" | "differentClient";
  }) => {
    if (!meetingID) {
      throw new Error("meetingID is not defined in updateWaitingRoom.");
    }
    if (!incomingClient?.id && !clientId) {
      throw new Error("incomingClient.id is not defined in updateWaitingRoom.");
    }
    const ok = await updateWaitingRoomOnServer(
      operation,
      meetingID,
      clientId ?? incomingClient?.id ?? "",
      admitPeerAs
    );
    if (ok) {
      dispatch(removeWaitingRoomAlertClient(incomingClient?.id ?? clientId));
      refetchWaitingRoom().catch(logUnexpectedError);
    } else {
      const errorMessage =
        operation === "admit"
          ? "There was an error admitting the participant."
          : "There was an error dismissing the participant.";
      logUnexpectedError(errorMessage);
      alert(`${errorMessage} Please try again later.`);
    }
  };
};

const updateWaitingRoomOnServer = async (
  operation: "admit" | "dismiss",
  meetingID: string,
  secretClientId: string,
  admitPeerAs: "additionalParticipant" | "differentClient"
) => {
  const endpoint =
    operation === "admit" ? "participant-admit" : "participant-dismiss";
  const result = await backendRequest({
    path: `/rooms/${meetingID}/${endpoint}`,
    options: {
      method: "POST",
      body: JSON.stringify({
        secretClientId,
        admitPeerAs,
        providerMixpanelUserId: mixpanel.get_distinct_id(),
      }),
      headers: {
        "Content-Type": "text/plain",
      },
      keepalive: true, // make sure the request is sent completely even once the page is destroyed
    },
  });
  return result.status === 200;
};

export const useClearWaitingRoom = (meetingURL: string) => {
  const meetingID = `URL_${meetingURL}`;

  return async () => {
    if (!meetingID) {
      throw new Error("meetingID is not defined in updateWaitingRoom.");
    }
    const result = await backendRequest({
      path: `/rooms/${meetingID}/clear-waiting-room`,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        keepalive: true, // make sure the request is sent completely even once the page is destroyed
      },
    });
    if (!result.ok && result.status !== 404) {
      throw new Error("Failed to clear waiting room");
    }
  };
};

const useAdmitAdditionalParticipant = () => {
  const dispatch = useDispatch();
  const admitClientOnServer = useAdmitClient();
  const logClientManagementEvent = useLogClientManagementEvent();
  const currentClient = useSelector(selectCurrentClient);
  const peerParticipants = usePeersParticipants();
  return async ({ id }: { id: string }) => {
    if (peerParticipants.length >= MAX_CLIENT_COUNT) {
      logUnexpectedError(
        new Error(
          "Trying to admit additional participant with 2 clients already connected"
        )
      );
      alert("Maximum of two clients can participate in this session.");
      return;
    }

    if (!currentClient || !currentClient.canonical_id) {
      logUnexpectedError(
        new Error(
          "Client is not seleced while admitting additional participant"
        )
      );
      alert(
        "An error occurred. You must have a client selected to admit an additional participant."
      );
      return;
    }
    await admitClientOnServer({
      clientId: id,
      admitPeerAs: "additionalParticipant",
    });
    dispatch(admitAdditionalParticipant());
    // not awaiting to let it run in the background
    logClientManagementEvent({
      action: "ADDITIONAL_CLIENT_ADMITTED",
      clientCanonicalId: currentClient?.canonical_id,
    });
  };
};

export const useWaitingRoomAdmitHandler = ({
  secretClientId,
  name,
}: {
  secretClientId: string;
  name: string;
}) => {
  const admitClientOnServer = useAdmitClient();
  const logClientManagementEvent = useLogClientManagementEvent();
  const ehrSystem = useSelector(selectSessionEHRSystem);
  const currentClient = useSelector(selectCurrentClient);
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);
  const dispatch = useDispatch();
  const admitAdditionalParticipant = useAdmitAdditionalParticipant();

  return async () => {
    if (!ehrSystem) {
      if (clientHasJoinedRoom) {
        await admitAdditionalParticipant({ id: secretClientId });
      } else {
        dispatch(triggerClientAdmission({ id: secretClientId, name: name }));
      }
    } else if (currentClient) {
      // admitting the client already selected from health system sign in
      await admitClientOnServer({ clientId: secretClientId });
      // not awaiting to let it run in the background
      logClientManagementEvent({
        action: "CLIENT_ADMITTED",
        clientCanonicalId: currentClient.canonical_id,
        duringSession: false,
      });
    } else {
      logUnexpectedError(
        "Admitting client in a health system without a selected client"
      );
      alert("No client selected. Please relaunch Teleo");
    }
  };
};
