import React, {
  PointerEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import styles from "./Videos.module.css";
import { useSelector } from "react-redux";
import { selectVideoConferencing } from "redux/settingsRedux";
import { VideoOrError } from "./VideoOrError";
import clsx from "clsx";
import { useFullScreenDimensions } from "./useFullScreenDimensions";
import { selectIsFullScreenVideo } from "redux/spaceNavigationRedux";
import { FloatingDelayGroup } from "@floating-ui/react";
import LaunchActivitySideBar from "./LaunchActivitySideBar/LaunchActivitySideBar";
import { useIsTherapist } from "pages/Space/hooks/useIsTherapist";
import { useSortedParticipants } from "../ConnectionsContext/WebRTCContext";

type VideosProps = {};

const INITIAL_POSITION = { top: 45, left: 40 };

export const Videos = ({}: VideosProps) => {
  const isTherapist = useIsTherapist();
  const [position, setPosition] = useState(INITIAL_POSITION);
  const positionRef = useRef(position);
  useEffect(() => {
    positionRef.current = position;
  }, [position]);
  const isFullScreen = useSelector(selectIsFullScreenVideo);
  const videoConferencing = useSelector(selectVideoConferencing);
  const containerRef = useRef(null);

  const dragging = useRef(false);
  const lastPos = useRef({ x: 0, y: 0 });

  const {
    setPeerRawVideoDimensions,
    setLocalRawVideoDimensions,
    peerFullScreenDimensions,
    localFullScreenDimensions,
    parentDimensions,
  } = useFullScreenDimensions(containerRef, !!isFullScreen);

  const movePositionIntoView = (pos: { top: number; left: number }) => {
    const newPos = { ...pos };
    if (pos.top < 0) {
      newPos.top = 0;
    }
    if (pos.left < 0) {
      newPos.left = 0;
    }
    if (pos.top + 100 > window.innerHeight) {
      newPos.top = window.innerHeight - 100;
    }
    if (pos.left + 100 > window.innerWidth) {
      newPos.left = window.innerWidth - 100;
    }
    return newPos;
  };

  const moveVideoIntoView = () => {
    const pos = positionRef.current;
    const newPos = movePositionIntoView(pos);
    if (pos.top !== newPos.top || pos.left !== newPos.left) {
      setPosition(newPos);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", moveVideoIntoView);

    return () => {
      window.removeEventListener("resize", moveVideoIntoView);
    };
  }, []);

  useEffect(() => {
    // Reset position when video conferencing setting is toggled, to be able to recover if the
    // video goes off screen
    if (videoConferencing) {
      setPosition(INITIAL_POSITION);
    }
  }, [videoConferencing]);

  const onMouseDown: PointerEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      dragging.current = true;
      lastPos.current = {
        x: event.clientX,
        y: event.clientY,
      };
      window.addEventListener("pointermove", onMouseMove);
      window.addEventListener("pointerup", onMouseUp);
    },
    []
  );

  const onMouseMove = useCallback((event: PointerEvent) => {
    if (!dragging.current) return;
    const deltaX = event.clientX - lastPos.current.x;
    const deltaY = event.clientY - lastPos.current.y;
    lastPos.current = {
      x: event.clientX,
      y: event.clientY,
    };
    setPosition((pos) => {
      if (!pos) {
        return pos;
      }
      const newPos = {
        top: pos.top + deltaY,
        left: pos.left + deltaX,
      };
      return movePositionIntoView(newPos);
    });
  }, []);

  const onMouseUp = useCallback(() => {
    dragging.current = false;
    window.removeEventListener("pointermove", onMouseMove);
    window.removeEventListener("pointerup", onMouseUp);
  }, [onMouseMove]);

  const sortedParticipants = useSortedParticipants();

  // The leftmost peer
  const remotePeers = sortedParticipants.filter(
    (participant) => !participant.isLocal
  );
  const lastPeerId =
    remotePeers.length > 0
      ? remotePeers[remotePeers.length - 1].identity
      : undefined;

  // The rightmost peer
  const firstPeerId = sortedParticipants[0].identity;
  const firstOtherPeerId = sortedParticipants[0].isLocal
    ? sortedParticipants[1]?.identity
    : firstPeerId;

  if (!videoConferencing) {
    return null;
  }

  const orderedVideos = sortedParticipants.map((participant) => (
    <VideoOrError
      key={participant.identity}
      participant={participant}
      isFullScreen={isFullScreen}
      setRawVideoDimensions={
        participant.isLocal
          ? setLocalRawVideoDimensions
          : setPeerRawVideoDimensions
      }
      peerFullScreenDimensions={peerFullScreenDimensions}
      localFullScreenDimensions={localFullScreenDimensions}
      parentDimensions={parentDimensions}
      peerCount={sortedParticipants.length}
      isLastPeer={
        sortedParticipants.length > 2 && participant.identity === lastPeerId
      }
      showExpandButton={
        participant.isLocal
          ? isFullScreen
            ? false
            : isTherapist
          : isFullScreen
          ? participant.identity === firstOtherPeerId
          : !isTherapist && participant.identity === firstPeerId
      }
    />
  ));

  // Add a placeholder video if there are no peers connected.
  // This might happen while the provider is alone in the room,
  // and if they go full screen, this will show the "Client is not connected" message
  if (orderedVideos.length <= 1) {
    orderedVideos.push(
      <VideoOrError
        key={"peerVideo-placeholder"}
        participant={"placeholder"}
        isFullScreen={isFullScreen}
        setRawVideoDimensions={setPeerRawVideoDimensions}
        peerFullScreenDimensions={peerFullScreenDimensions}
        localFullScreenDimensions={localFullScreenDimensions}
        parentDimensions={parentDimensions}
        peerCount={orderedVideos.length + 1}
        showExpandButton={!!isFullScreen}
      />
    );
  }

  return (
    <div className={styles.fullScreenWrapper}>
      <div
        ref={containerRef}
        className={clsx({
          ["minimizedVideoContainer"]: !isFullScreen,
          ["fullScreenVideoContainer"]: isFullScreen,
        })}
        style={isFullScreen ? undefined : position}
        onPointerDown={onMouseDown}
      >
        <FloatingDelayGroup
          delay={{
            open: 500,
            close: 500,
          }}
        >
          {orderedVideos}
        </FloatingDelayGroup>
      </div>
      {isTherapist && isFullScreen && (
        <>
          <div className={styles.fullScreenOverlaySpacing} />
          <LaunchActivitySideBar />
        </>
      )}
    </div>
  );
};
