import React from "react";

import styles from "./SettingsModal.module.css";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  resetNavigation,
  setShowSettingsModal,
  SpacePage,
} from "redux/spaceNavigationRedux";
import Modal from "components/Modal/Modal";
import Toggle from "components/Toggle/Toggle";
import {
  selectAudioProviderOnly,
  selectVideoConferencing,
  setAudioProviderOnly,
  setVideoConferencing,
} from "redux/settingsRedux";
import {
  useSetAudioProviderOnlyMutation,
  useSetVideoConferencingMutation,
} from "generated/graphql";
import { selectCurrentRoomId, selectUserId } from "redux/userRedux";
import { useAppSignOut } from "utils/appSignOutUtils";
import { showOnboardingGuide } from "utils/pendoUtils";
import { resetEditRoomNavigation } from "redux/editRoomNavigationRedux";
import { logUnexpectedError } from "utils/errorUtils";
import clsx from "clsx";
import { logVideoConferencingEnabled } from "utils/metricsUtils";
import { ReactComponent as SignOutIcon } from "assets/icons/leave.svg";
import { ReactComponent as HelpIcon } from "assets/icons/help.svg";
import BillingCounter from "components/Billing/BillingCounter";
import { useTeleoEvent } from "../ConnectionsContext/teleoPeerEventUtils";
import { useLeave } from "../ConnectionsContext/GatewayConnectionContext";

type SettingsModalProps = {};

const SettingsModal = ({}: SettingsModalProps) => {
  const dispatch = useDispatch();
  const appSignOut = useAppSignOut();
  const leave = useLeave();

  const audioProviderOnly = useSelector(selectAudioProviderOnly);
  const videoConferencing = useSelector(selectVideoConferencing);
  const userId = useSelector(selectUserId);
  const roomId = useSelector(selectCurrentRoomId);
  const [setAudioProviderOnlyMutation] = useSetAudioProviderOnlyMutation();
  const [setVideoConferencingMutation] = useSetVideoConferencingMutation();

  const closeModal = () => {
    dispatch(setShowSettingsModal(false));
  };

  const signOut = async () => {
    leave("Signing out...");
    await appSignOut();
  };
  const emitAudioProviderOnly = useTeleoEvent("audio-provider-only");
  const emitVideoConferencing = useTeleoEvent("video-conferencing");
  const emitNavigate = useTeleoEvent("navigate");

  const toggleAudioProviderOnly = () => {
    if (userId && !videoConferencing) {
      const newValue = !audioProviderOnly;
      // TODO: synchronize these better (probably just set value in DB with optimistic response)
      dispatch(setAudioProviderOnly(newValue));
      setAudioProviderOnlyMutation({
        variables: { userId, audioProviderOnly: newValue },
      }).catch(logUnexpectedError);
      emitAudioProviderOnly({ audioProviderOnly: newValue });
    }
  };

  const toggleVideoConferencing = () => {
    if (userId) {
      const newValue = !videoConferencing;
      // TODO: synchronize these better (probably just set value in DB with optimistic response)
      dispatch(setVideoConferencing(newValue));
      setVideoConferencingMutation({
        variables: { userId, videoConferencing: newValue },
      }).catch(logUnexpectedError);
      emitVideoConferencing({ videoConferencing: newValue });
      logVideoConferencingEnabled();
    }
  };

  const runOnboardingGuide = () => {
    closeModal();

    // Navigate to the main room
    dispatch(resetNavigation());
    emitNavigate({
      currentPage: SpacePage.ROOM,
    });
    dispatch(resetEditRoomNavigation());

    showOnboardingGuide();
  };

  const actualAudioProviderOnly = audioProviderOnly && !videoConferencing;
  const audioToggleText = actualAudioProviderOnly ? "Only Yours" : "Both";
  const videoToggleText = videoConferencing ? "On" : "Off";

  const noCurrentRoom = roomId === undefined;

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.heading}>Settings</div>
      <div className={styles.wrapper}>
        <BillingCounter containerClassName={styles.billingCounter} />
        <div className={styles.spacer} />
        <div className={styles.row} onClick={toggleVideoConferencing}>
          <div className={styles.text}>Video Conferencing</div>
          <Toggle isOn={!!videoConferencing} text={videoToggleText} />
        </div>
        <div className={styles.smallText}>
          {"Enable or disable video chat."}
        </div>
        <div className={styles.row} onClick={toggleAudioProviderOnly}>
          <div
            className={clsx(styles.text, {
              [styles.disabledText]: videoConferencing,
            })}
          >
            Play audio from both devices
          </div>
          <Toggle
            isOn={!actualAudioProviderOnly}
            text={audioToggleText}
            disabled={videoConferencing}
          />
        </div>
        {videoConferencing ? (
          <div className={styles.warningText}>
            {"⚠️ Disable video conferencing to adjust this setting."}
          </div>
        ) : null}
        <div
          className={clsx(styles.smallText, {
            [styles.disabledText]: videoConferencing,
          })}
        >
          {
            "Playing audio from both your computer and your client's computer can cause an echo if both devices are transmitting audio, but is necessary if you are wearing headphones and not sharing audio."
          }
        </div>
        <div className={styles.sectionHeader}>
          Help <HelpIcon className={styles.helpIcon} />
        </div>
        <div className={styles.row}>
          <div className={styles.text}>View Teleo onboarding tour</div>
          <Button
            onClick={runOnboardingGuide}
            className={styles.tourButton}
            disabled={noCurrentRoom}
          >
            Launch
          </Button>
        </div>
        {noCurrentRoom ? (
          <div className={styles.smallText}>
            {
              "⚠️ You must be in a room to view the onboarding tour. Please open a room and try again."
            }
          </div>
        ) : null}

        <div className={clsx(styles.row, styles.getSupport)}>
          <div className={styles.text}>Get Support</div>
          <Button
            href="https://www.teleo.space/support"
            className={styles.supportButton}
          >
            Get Help
          </Button>
        </div>
        <div className={clsx(styles.smallText)}>
          Read how-to guides, connect with our therapist community, or get help
          from the Teleo team.
        </div>
        <Button className={styles.signOutButton} onClick={signOut}>
          <SignOutIcon className={styles.signOutIcon} />
          <span className={styles.signOutText}>Sign Out</span>
        </Button>
      </div>
    </Modal>
  );
};

export default SettingsModal;
