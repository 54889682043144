import React, { useEffect, useMemo, useState } from "react";

import { ReactComponent as XIcon } from "assets/icons/x.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as SwitchIcon } from "assets/icons/switch_person.svg";

import styles from "./ClientFile.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  SnapshotDetail,
  openLoadModal,
  selectClientHasJoinedRoom,
  selectCurrentClient,
  setSnapshotDetail,
  toggleClientFileOpen,
  toggleShowConfirmClientDelete,
} from "redux/clientManagementRedux";
import clsx from "clsx";
import {
  GetAlbumSnapshotsDocument,
  useGetSessionsQuery,
  useGetSnapshotsQuery,
  useSetSnapshotSharedWithClientMutation,
} from "generated/graphql";
import { getFileUrls } from "utils/fileUtils";
import { selectDecodedAuthToken } from "redux/userRedux";
import ClientDeleteConfirmModal from "./ClientDeleteConfirmModal";
import {
  useLogClientManagementEvent,
  useLogSnapshotSharedCount,
} from "utils/metricsUtils";
import albumIcon from "assets/itemIcons/album.png";
import { logUnexpectedError } from "utils/errorUtils";
import { selectSessionEHRSystem } from "redux/ehrSystemRedux";
import { useTrackClientFile } from "../ClientManagement/useClientManagementAnalytics";
import Tooltip from "components/Tooltip/Tooltip";

type TimelineList = {
  month: { label: string; id: string };

  days: {
    label: string;
    id: string;
    snapshots: {
      id: string;
      fileKey: string;
      thumbnailKey: string;
      resourceName: string;
      clientName: string;
      createdAt: string;
      sharedWithClient: boolean;
    }[];
  }[];
}[];

const formatMinutes = (minutes: number) => {
  if (minutes > 1) {
    return `${minutes} mins`;
  } else if (minutes === 1) {
    return "1 min";
  } else {
    return "Less than 1 min";
  }
};

const formatShortDate = (date: Date) =>
  date.toLocaleString("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });

const ClientFile = () => {
  const [imgKeysToURL, setImgKeysToURL] = useState<{ [key: string]: string }>(
    {}
  );
  const currentClient = useSelector(selectCurrentClient);
  const ehrSystem = useSelector(selectSessionEHRSystem);
  const decodedAuthToken = useSelector(selectDecodedAuthToken);

  const { data: snapshotData } = useGetSnapshotsQuery({
    variables: {
      clientCanonicalId: currentClient?.canonical_id,
    },
  });

  const { data: sessionData } = useGetSessionsQuery({
    variables: {
      clientCanonicalId: currentClient?.canonical_id!,
    },
    skip: !currentClient?.canonical_id,
    fetchPolicy: "cache-and-network",
  });

  const dispatch = useDispatch();

  const logClientManagementEvent = useLogClientManagementEvent();
  const { trackDeleteClicked, trackSwitchClicked } = useTrackClientFile();

  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);

  const [setSnapshotSharedWithClient] =
    useSetSnapshotSharedWithClientMutation();

  const logSnapshotSharedCount = useLogSnapshotSharedCount();

  useEffect(() => {
    if (currentClient) {
      // not awaiting to let it run in the background
      logClientManagementEvent({
        action: "CLIENT_SIDEBAR_OPENED",
        clientCanonicalId: currentClient.canonical_id,
        duringSession: clientHasJoinedRoom,
      });
    }
  }, []);

  const [timelineList, imgKeys]: [TimelineList, string[]] = useMemo(() => {
    const imgKeys: string[] = [];
    const timelineList: TimelineList = [];

    const snapshotsByDate = snapshotData?.resource_snapshot.reduce(
      (acc, snapshot) => {
        const date = formatShortDate(new Date(snapshot.created_at));
        (acc[date] ??= []).push(snapshot);
        return acc;
      },
      {} as Record<string, typeof snapshotData.resource_snapshot>
    );

    const sessionsByDate = sessionData?.user_session.reduce((acc, session) => {
      const date = formatShortDate(new Date(session.start_timestamp));
      (acc[date] ??= []).push(session);
      return acc;
    }, {} as Record<string, typeof sessionData.user_session>);

    const allDates = Array.from(
      new Set([
        ...Object.keys(snapshotsByDate ?? {}),
        ...Object.keys(sessionsByDate ?? {}),
      ])
    ).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    allDates.forEach((date) => {
      const snapshotDate = new Date(date);
      const month = snapshotDate.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
      });

      let currentMonth = timelineList[timelineList.length - 1];
      if (!currentMonth || currentMonth.month.id !== month) {
        currentMonth = {
          month: {
            label: `${snapshotDate.toLocaleString("en-US", {
              month: "long",
            })} ${snapshotDate.toLocaleString("en-US", { year: "numeric" })}`,
            id: month,
          },
          days: [],
        };
        timelineList.push(currentMonth);
      }

      const day = formatShortDate(snapshotDate);
      const snapshots = snapshotsByDate?.[date] ?? [];
      const sessions = sessionsByDate?.[date] ?? [];

      let currentDay = currentMonth.days[currentMonth.days.length - 1];
      if (!currentDay || currentDay.id !== day) {
        const dateString = formatShortDate(snapshotDate);
        const todayDateString = formatShortDate(new Date());
        const totalDuration = sessions.reduce(
          // Session duration is measured in ms
          (total, session) => total + Math.round(session.duration / 1000 / 60),
          0
        );
        const hasSessions = sessions.length > 0;
        const isToday = dateString === todayDateString;
        const minutesString = hasSessions ? formatMinutes(totalDuration) : "";
        const labelSuffix =
          isToday && clientHasJoinedRoom ? "Current Session" : minutesString;
        currentDay = {
          id: day,
          label: labelSuffix ? `${dateString} - ${labelSuffix}` : dateString,
          snapshots: [],
        };
        currentMonth.days.push(currentDay);
      }

      snapshots.forEach((snapshot) => {
        currentDay.snapshots.push({
          id: snapshot.id,
          fileKey: snapshot.file_key,
          thumbnailKey: snapshot.thumbnail_file_key,
          resourceName: snapshot.resource.name ?? "",
          clientName: snapshot.client.name,
          createdAt: snapshot.created_at,
          sharedWithClient: snapshot.shared_with_client,
        });
        imgKeys.push(snapshot.file_key, snapshot.thumbnail_file_key);
      });
    });

    return [timelineList, imgKeys];
  }, [
    snapshotData?.resource_snapshot,
    sessionData?.user_session,
    clientHasJoinedRoom,
  ]);

  useEffect(() => {
    const getURLsFromKeys = async () => {
      setImgKeysToURL(await getFileUrls(imgKeys, "snapshot"));
    };
    getURLsFromKeys();
  }, [imgKeys]);

  const handleSnapshotClick = (snapshot: SnapshotDetail) => {
    dispatch(setSnapshotDetail(snapshot));
  };

  const handleShareClick = async (
    snapshotId: string,
    isCurrentlyShared: boolean
  ) => {
    const result = await setSnapshotSharedWithClient({
      variables: {
        snapshotId: snapshotId,
        shared: !isCurrentlyShared,
      },
      refetchQueries: [
        {
          query: GetAlbumSnapshotsDocument,
          variables: { clientId: currentClient?.canonical_id },
        },
      ],
    });
    if (!result.data?.update_resource_snapshot_by_pk?.id) {
      logUnexpectedError(
        new Error("Failed to set snapshot as shared with client")
      );
      return;
    }
    logSnapshotSharedCount();
  };

  if (!currentClient) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        <div className={styles.actionButtons}>
          {(!ehrSystem || !decodedAuthToken.epicConferenceID) && (
            <div
              className={styles.actionButton}
              onClick={() => {
                trackSwitchClicked();
                dispatch(openLoadModal());
              }}
            >
              <SwitchIcon className={styles.switchIcon} />
              Switch
            </div>
          )}
          {!ehrSystem && (
            <div
              className={clsx(styles.actionButton, styles.deleteButton)}
              onClick={() => {
                trackDeleteClicked();
                dispatch(toggleShowConfirmClientDelete());
              }}
            >
              <ClientDeleteConfirmModal />
              <DeleteIcon />
              Delete
            </div>
          )}
        </div>
        <Tooltip text={currentClient.name}>
          <div className={styles.name}>{currentClient.name}</div>
        </Tooltip>
        <XIcon
          className={styles.XIcon}
          onClick={() => dispatch(toggleClientFileOpen())}
        />
      </div>
      <div className={styles.snapshotListContainer}>
        {timelineList.length > 0 ? (
          timelineList.map((month) => (
            <div key={month.month.label} className={styles.monthContainer}>
              <div className={styles.monthLabel}>{month.month.label}</div>
              <div>
                {month.days.map((day) => (
                  <div key={day.id} className={styles.dayContainer}>
                    {day.label}
                    <div className={styles.snapshotList}>
                      {day.snapshots.map((snapshot) => (
                        <div
                          key={snapshot.id}
                          className={styles.snapshot}
                          onClick={() =>
                            handleSnapshotClick({
                              clientName: snapshot.clientName,
                              id: snapshot.id,
                              fileKey: snapshot.fileKey,
                              fileUrl: imgKeysToURL[snapshot.fileKey],
                              resource: snapshot.resourceName,
                              created_at: snapshot.createdAt,
                              sharedWithClient: snapshot.sharedWithClient,
                            })
                          }
                        >
                          <div className={styles.snapshotImgContainer}>
                            <img src={imgKeysToURL[snapshot.thumbnailKey]} />
                            <div
                              className={clsx(styles.shareButton, {
                                [styles.isShared]: snapshot.sharedWithClient,
                              })}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleShareClick(
                                  snapshot.id,
                                  snapshot.sharedWithClient
                                );
                              }}
                            >
                              <img src={albumIcon} />
                            </div>
                          </div>
                          <div className={styles.snapshotResourceName}>
                            {snapshot.resourceName}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className={styles.emptyMessage}>
            <p>No snapshots have been taken.</p>
            <p>
              Open an activity and click “Snapshot” in the bottom control bar to
              save a snapshot to the client record.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientFile;
