import { RoomItemFragment } from "generated/graphql";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsActivityBankOpen,
  setEditItemMoving,
  setIsActivityBankOpen,
} from "redux/editRoomNavigationRedux";
import { resourceToAction } from "utils/resourceUtils";
import { Platform } from "../itemsConfig";
import {
  setBrowserSandboxUrl,
  setCurrentPage,
  setCurrentResourceId,
  setCurrentRoomItemId,
  setHasLaunchedActivityFromFullScreenVideo,
  setShowRoomCustomizationActivityModal,
  setWhiteboardBackground,
  SpacePage,
} from "redux/spaceNavigationRedux";
import { useBoxRef } from "hooks/useBoxRef";
import { useRoomEditingCloseActivityBankAnalytics } from "./useRoomEditingAnalytics";
import { useTeleoEvent } from "pages/Space/components/ConnectionsContext/teleoPeerEventUtils";

export const useLaunchActivity = () => {
  const dispatch = useDispatch();
  const isActivityBankOpen = useSelector(selectIsActivityBankOpen);
  const isActivityBankOpenRef = useBoxRef(isActivityBankOpen);
  const { trackCloseActivityBank } = useRoomEditingCloseActivityBankAnalytics();

  const emitNavigate = useTeleoEvent("navigate");

  return (
    roomItem: RoomItemFragment | undefined,
    isLaunchingFromFullScreenVideo: boolean
  ) => {
    if (isActivityBankOpenRef.current) {
      trackCloseActivityBank("Launch Activity");
    }
    dispatch(setEditItemMoving(undefined));
    dispatch(setIsActivityBankOpen(false));

    if (!roomItem) {
      return;
    }

    const resource = roomItem.resource;
    if (!resource) {
      return;
    }
    const onClick = resourceToAction(resource);
    const resourceId = resource.id;

    dispatch(
      setHasLaunchedActivityFromFullScreenVideo(isLaunchingFromFullScreenVideo)
    );

    if (onClick?.type === Platform.WHITEBOARD) {
      dispatch(setWhiteboardBackground(onClick.backgroundImageKey ?? null));
      dispatch(setCurrentPage(SpacePage.WHITEBOARD));
      dispatch(setCurrentResourceId(resourceId));
      dispatch(setCurrentRoomItemId(roomItem.id));
      emitNavigate({
        currentPage: SpacePage.WHITEBOARD,
        whiteboardBackground: onClick.backgroundImageKey,
        resourceId,
        roomItemId: roomItem.id,
        isLaunchingFromFullScreenVideo,
      });
    } else if (onClick?.type === Platform.BROWSER_SANDBOX) {
      dispatch(setBrowserSandboxUrl(onClick.url));
      dispatch(setCurrentPage(SpacePage.BROWSER_SANDBOX));
      dispatch(setCurrentResourceId(resourceId));
      dispatch(setCurrentRoomItemId(roomItem.id));
      emitNavigate({
        currentPage: SpacePage.BROWSER_SANDBOX,
        browserSandboxUrl: onClick.url,
        resourceId,
        roomItemId: roomItem.id,
        isLaunchingFromFullScreenVideo,
      });
    } else if (onClick?.type === Platform.MEDIA_PLAYER) {
      // TODO: add new state variable for media player
      dispatch(setBrowserSandboxUrl(onClick.url));
      dispatch(setCurrentPage(SpacePage.MEDIA_PLAYER));
      dispatch(setCurrentResourceId(resourceId));
      dispatch(setCurrentRoomItemId(roomItem.id));
      emitNavigate({
        currentPage: SpacePage.MEDIA_PLAYER,
        browserSandboxUrl: onClick.url,
        resourceId,
        roomItemId: roomItem.id,
        isLaunchingFromFullScreenVideo,
      });
    } else if (onClick?.type === Platform.ROOM_CUSTOMIZATION) {
      dispatch(setShowRoomCustomizationActivityModal(true));
      dispatch(setCurrentResourceId(resourceId));
      dispatch(setCurrentRoomItemId(roomItem.id));
      emitNavigate({
        currentPage: SpacePage.ROOM,
        showRoomCustomizationActivityModal: true,
        resourceId,
        roomItemId: roomItem.id,
      });
    } else if (onClick?.type === Platform.ALBUM) {
      dispatch(setCurrentPage(SpacePage.ALBUM));
      dispatch(setCurrentResourceId(resourceId));
      dispatch(setCurrentRoomItemId(roomItem.id));
      emitNavigate({
        currentPage: SpacePage.ALBUM,
        resourceId,
        roomItemId: roomItem.id,
        isLaunchingFromFullScreenVideo,
      });
    }
  };
};
