import { useTeleoEvent } from "pages/Space/components/ConnectionsContext/teleoPeerEventUtils";
import { useEffect } from "react";
import { useIsTherapist } from "../useIsTherapist";
import {
  getLocalPeerId,
  useIsWebRTCConnected,
  useLocalUserMediaError,
} from "pages/Space/components/ConnectionsContext/WebRTCContext";
import { useDispatch } from "react-redux";
import { setPeerMediaError } from "redux/settingsRedux";
import { logUnexpectedError } from "utils/errorUtils";
import { useBoxRef } from "hooks/useBoxRef";

export const useEmitLocalUserMediaError = () => {
  const isTherapist = useIsTherapist();
  const localUserMediaError = useLocalUserMediaError();
  const localUserMediaErrorRef = useBoxRef(localUserMediaError);

  const emitUserMediaError = useTeleoEvent("userMediaError");

  return () => {
    console.log(
      "re-emitting user media error after sync",
      localUserMediaErrorRef.current,
      isTherapist
    );
    if (!isTherapist) {
      emitUserMediaError({
        error: localUserMediaErrorRef.current,
        senderId: getLocalPeerId(),
      });
    }
  };
};

export const useSyncMediaError = () => {
  const isTherapist = useIsTherapist();
  const localUserMediaError = useLocalUserMediaError();
  const dispatch = useDispatch();
  const isConnected = useIsWebRTCConnected();

  const emitUserMediaError = useTeleoEvent(
    "userMediaError",
    ({ error, senderId }) => {
      if (!senderId) {
        logUnexpectedError("No senderId in userMediaError event");
        return;
      }
      dispatch(setPeerMediaError({ mediaError: error, peerId: senderId }));
    }
  );
  useEffect(() => {
    console.log(
      "sync media error effect ran",
      localUserMediaError,
      isConnected,
      isTherapist
    );
    if (!isTherapist) {
      emitUserMediaError({
        error: localUserMediaError,
        senderId: getLocalPeerId(),
      });
    }
    // isConnected added here to run this effect again when connected
  }, [localUserMediaError, isConnected]);
};
