import React, { useState } from "react";

import styles from "./RoomTypeModal.module.css";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";
import {
  useGetRoomTypeModalDataQuery,
  useInsertRoomAndMakeCurrentMutation,
} from "generated/graphql";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId, setCurrentRoomId } from "redux/userRedux";
import { COLLECTION_ID } from "pages/Space/subpages/SpaceRoom/utils/backgroundUtils";
const playroomImage = require("assets/rooms/younger.png");
const defaultOlderImage = require("assets/rooms/older.png");
import faceIcon from "assets/icons/face.svg";
import skateboarderIcon from "assets/icons/skateboarder.svg";
import clsx from "clsx";
import { logUsageEvent } from "utils/metricsUtils";
import { logUnexpectedError } from "utils/errorUtils";

type RoomTypeModalProps = {
  close: () => void;
  onSubmit: () => Promise<void>;
};

const RoomTypeModal = ({ onSubmit, close }: RoomTypeModalProps) => {
  const userId = useSelector(selectUserId);
  const [selectedRoomType, setSelectedRoomType] = useState(
    COLLECTION_ID.YOUNGER
  );
  const [loadingCreateRoom, setLoadingCreateRoom] = useState(false);
  const [insertRoomAndMakeCurrent] = useInsertRoomAndMakeCurrentMutation();
  const dispatch = useDispatch();

  const { data } = useGetRoomTypeModalDataQuery();

  const selectYounger = () => {
    setSelectedRoomType(COLLECTION_ID.YOUNGER);
  };

  const selectOlder = () => {
    setSelectedRoomType(COLLECTION_ID.OLDER);
  };

  const submit = async () => {
    try {
      setLoadingCreateRoom(true);
      const roomData =
        selectedRoomType === COLLECTION_ID.YOUNGER
          ? data?.defaultYoungerRoom
          : data?.defaultOlderRoom;

      if (!roomData) {
        return;
      }

      const roomItems = roomData.room_items.map(
        // Remove '__typename' (added by Apollo) and 'id' before inserting items
        (item) => ({
          resource_id: item.resource_id,
          icon_id: item.icon_id,
          rx: item.rx,
          ry: item.ry,
          z: item.z,
        })
      );
      const backgroundId = roomData.background_id;
      const collectionId = roomData.collection_id;
      const { data: result } = await insertRoomAndMakeCurrent({
        variables: {
          userId,
          roomItems,
          backgroundId,
          collectionId,
          roomName: "Room 1",
          isFirstRoom: true,
        },
      });
      logUsageEvent("ADDED_ROOM", undefined);
      logUsageEvent("BACKGROUND_CHANGED", undefined);
      // Update current room
      dispatch(setCurrentRoomId(result?.insert_room_one?.id));
      await onSubmit();
    } catch (err) {
      logUnexpectedError(err);
      alert("There was an error creating your room. Please try again later.");
    } finally {
      setLoadingCreateRoom(false);
    }
  };

  const buttonIsDisabled = !data || loadingCreateRoom;

  return (
    <Modal closeModal={close}>
      <div className={styles.heading}>Create Your First Room</div>
      <div className={styles.optionsRow}>
        <div
          className={clsx(styles.option, {
            [styles.optionSelected]: selectedRoomType === COLLECTION_ID.YOUNGER,
          })}
          onClick={selectYounger}
        >
          <img className={styles.optionImage} src={playroomImage} />
          <div className={styles.optionLabel}>
            <img className={styles.optionIcon} src={faceIcon} />
            Younger
          </div>
        </div>
        <div
          className={clsx(styles.option, {
            [styles.optionSelected]: selectedRoomType === COLLECTION_ID.OLDER,
          })}
          onClick={selectOlder}
        >
          <img className={styles.optionImage} src={defaultOlderImage} />
          <div className={styles.optionLabel}>
            <img className={styles.optionIcon} src={skateboarderIcon} />
            Older
          </div>
        </div>
      </div>
      <Button onClick={submit} disabled={buttonIsDisabled}>
        {buttonIsDisabled ? "..." : "Create!"}
      </Button>
    </Modal>
  );
};

export default RoomTypeModal;
