import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

import styles from "pages/Space/components/Videos/VideosButtons/ConnectionQualityIndicator.module.css";
import Tooltip from "components/Tooltip/Tooltip";
import { ReactComponent as PoorConnection } from "assets/icons/poor_connection.svg";
import { ReactComponent as BadConnection } from "assets/icons/bad_connection.svg";
import { selectIsFullScreenVideo } from "redux/spaceNavigationRedux";
import { useIsTherapist } from "pages/Space/hooks/useIsTherapist";
import { useLocalUserConnectionQuality } from "../../ConnectionsContext/WebRTCContext";

type ConnectionQualityIndicatorProps = {
  className: string;
  style?: React.CSSProperties;
};

export const ConnectionQualityIndicator = ({
  className,
  style,
}: ConnectionQualityIndicatorProps) => {
  const isTherapist = useIsTherapist();
  const isFullScreen = useSelector(selectIsFullScreenVideo);

  const connectionQuality = useLocalUserConnectionQuality();

  const hasIssues =
    connectionQuality === "poor" || connectionQuality === "lost";

  useEffect(() => {
    console.log(
      "ConnectionQualityIndicator.tsx: connectionQuality: ",
      connectionQuality,
      hasIssues
    );
  }, [connectionQuality]);
  if (!hasIssues) {
    return;
  }
  const isBadConnection = connectionQuality === "lost";

  const tooltipText = (
    <>
      <b>{isBadConnection ? "Bad" : "Slow"} Internet</b>
      <br />
      Try moving to where the signal is stronger
      {isTherapist && (
        <>
          <br />
          or turn off everyone&apos;s camera
        </>
      )}
    </>
  );

  return (
    <div className={className} style={style}>
      <Tooltip text={tooltipText}>
        <div
          className={clsx(styles.connectionQualityIndicator, {
            [styles.fullScreenButton]: isFullScreen,
          })}
        >
          {isBadConnection ? (
            <BadConnection
              className={clsx(styles.icon, styles.badConnection)}
            />
          ) : (
            <PoorConnection className={styles.icon} />
          )}
        </div>
      </Tooltip>
    </div>
  );
};
