import { useSelector } from "react-redux";
import {
  selectEmail,
  selectUserFirstName,
  selectUserId,
  selectUserJoinTimestamp,
  selectUserRole,
  UserRole,
} from "redux/userRedux";
import { useEffect } from "react";
import Intercom from "@intercom/messenger-js-sdk";

export const useIntercom = (agreedToTerms: boolean) => {
  const userId = useSelector(selectUserId);
  const email = useSelector(selectEmail);
  const firstName = useSelector(selectUserFirstName);
  const userJoinTimestamp = useSelector(selectUserJoinTimestamp);
  const userRole = useSelector(selectUserRole);

  const isTherapist = userRole === UserRole.THERAPIST;

  useEffect(() => {
    // Initialize intercom only for therapists who have agreed to the terms and conditions.
    if (
      process.env.REACT_APP_INTERCOM_APP_ID &&
      isTherapist &&
      agreedToTerms &&
      userId &&
      email &&
      firstName !== undefined &&
      userJoinTimestamp
    ) {
      // Intercom will update properties without reinitializing on subsequent calls
      const createdAt = Date.parse(userJoinTimestamp) / 1000;
      // eslint-disable-next-line new-cap
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: userId,
        email: email,
        name: firstName || undefined, // Intercom will not unset the name if it's undefined
        created_at: createdAt,
      });
    }
  }, [isTherapist, agreedToTerms, userId, email, firstName, userJoinTimestamp]);
};
