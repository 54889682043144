import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./DemoStart.module.css";
import editIcon from "assets/icons/rename.svg";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEmail,
  selectEncodedAuthToken,
  selectOrganizationId,
  selectUserId,
  setCurrentRoomId,
} from "redux/userRedux";
import { useGetDemoStartDataQuery } from "generated/graphql";
import { useAppSignOut } from "utils/appSignOutUtils";
import TermsModal from "./components/TermsModal/TermsModal";
import CenteredLoadingDiv from "components/CenteredLoadingDiv/CenteredLoadingDiv";
import mixpanel from "mixpanel-browser";
import { usePendo } from "utils/pendoUtils";
import RoomTypeModal from "./components/RoomTypeModal/RoomTypeModal";
import { useSetUrlName } from "./hooks/useSetUrlName";
import CopyButton from "pages/Space/components/CopyButton/CopyButton";
import { EditLinkInput } from "./components/EditLinkInput/EditLinkInput";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import {
  selectShowInviteModal,
  selectShowUpgradeModal,
  setShowInviteModal,
} from "redux/spaceNavigationRedux";
import InviteModal from "pages/Space/components/InviteModal/InviteModal";
import OutsideRoomLayout from "components/OutsideRoomLayout/OutsideRoomLayout";
import { backendRequest } from "utils/backendRequest";
import { getOrganizationConfig } from "utils/organizationUtils";
import { useIntercom } from "pages/Space/hooks/useIntercom";
import BillingCounter from "components/Billing/BillingCounter";
import UpgradeModal from "components/UpgradeModal/UpgradeModal";
import { useSubscriptionContext } from "components/SubscriptionContext/SubsciptionContext";
import Overlay from "components/Overlay/Overlay";

const DemoStart = () => {
  const navigate = useNavigate();
  const userId = useSelector(selectUserId);
  const email = useSelector(selectEmail);
  const encodedAuthToken = useSelector(selectEncodedAuthToken);
  const organizationId = useSelector(selectOrganizationId);
  const organizationConfig = getOrganizationConfig(organizationId);
  const showInviteModal = useSelector(selectShowInviteModal);
  const showUpgradeModal = useSelector(selectShowUpgradeModal);
  const signOut = useAppSignOut();
  const { shouldShowPaywall, hidePaywall } = useSubscriptionContext();
  const [loadingOpenRoom, setLoadingOpenRoom] = useState(false);
  const [showRoomTypeModal, setShowRoomTypeModal] = useState(false);
  const [editLinkMode, setEditLinkMode] = useState(false);
  const dispatch = useDispatch();

  const { data, loading } = useGetDemoStartDataQuery({
    variables: { userId: userId || "" },
    skip: !userId,
  });

  const agreedToTerms =
    !!data?.user_by_pk?.agreed_to_terms || !!data?.user_by_pk?.health_system_id;
  const urlName = data?.user_by_pk?.provider_settings?.url_name;

  useSetUrlName(data);

  useIntercom(agreedToTerms);
  usePendo(agreedToTerms);

  const launchRoomOrCreateFirstRoom = async () => {
    setLoadingOpenRoom(true);

    // @ts-ignore
    window.pendo.onGuideAdvanced(2);

    const currentRoomId = data?.user_by_pk?.current_room?.id;
    const hasCreatedRoom =
      data?.user_by_pk?.rooms_aggregate?.aggregate?.count || 0 > 0;
    if (hasCreatedRoom) {
      if (currentRoomId) {
        dispatch(setCurrentRoomId(currentRoomId));
      }
      await launchNewRoom();
    } else {
      setShowRoomTypeModal(true);
    }
  };

  const launchNewRoom = async () => {
    setLoadingOpenRoom(true);
    if (!encodedAuthToken || !userId) {
      setLoadingOpenRoom(false);
      alert("Internal error, please try again later.");
      return;
    }
    // Launch room & get room id (should match the provider settings urlName, but use the response from the server
    // in case of any synchronization issues)
    const mixpanelUserId = mixpanel.get_distinct_id();

    const result = await backendRequest({
      path: "/rooms",
      searchParams: { mixpanelUserId },
      options: { method: "PUT" },
    });

    if (result.status !== 200) {
      const { error } = await result.json();
      const errorMessage =
        error === "No server capacity"
          ? "Teleo is at capacity and cannot open another room. Please try again later."
          : "Internal error, please try again later.";
      setLoadingOpenRoom(false);
      alert(errorMessage);
      return;
    }
    const { userUrl } = await result.json();

    setLoadingOpenRoom(false);

    // Navigate to the space at that url
    navigate(`/space/${userUrl}`);
  };

  const handleCloseRoomTypeModal = () => {
    setShowRoomTypeModal(false);
    setLoadingOpenRoom(false);
  };

  const showInvite = () => {
    dispatch(setShowInviteModal(true));
  };

  if (loading) {
    return <CenteredLoadingDiv />;
  }

  const baseUrl = window.location.href + "space/";
  const fullUrl = agreedToTerms ? baseUrl + urlName : "---";

  return (
    <>
      <OutsideRoomLayout>
        <div className={styles.contentWrapper}>
          <div className={styles.accountText}>Signed in as {email}</div>
          <div className={styles.linkSection}>
            <div className={styles.messageText}>
              To invite a client to your waiting room, share this link:
            </div>
            {editLinkMode ? (
              <EditLinkInput
                urlName={urlName}
                closeEditLinkMode={() => setEditLinkMode(false)}
              />
            ) : (
              <div className={styles.linkRow}>
                <div className={styles.urlTextBox}>
                  <div className={styles.urlText}>{fullUrl}</div>
                  {!organizationConfig?.shouldHideEditRoomLink && (
                    <img
                      className={styles.editIcon}
                      src={editIcon}
                      alt={"Edit Link"}
                      onClick={() => setEditLinkMode(true)}
                    />
                  )}
                </div>
                <CopyButton textToCopy={fullUrl} />
                <Button
                  onClick={showInvite}
                  className={styles.inviteButton}
                  secondary
                >
                  <MailIcon className={styles.mailIcon} />
                  Send
                </Button>
              </div>
            )}
          </div>
          <Button
            id={"openRoomButton"}
            onClick={launchRoomOrCreateFirstRoom}
            disabled={loadingOpenRoom || loading || !urlName}
          >
            {loadingOpenRoom || loading ? "..." : "Open Room"}
          </Button>
          <Button className={styles.signOutButton} onClick={signOut}>
            Sign Out
          </Button>
        </div>
        <BillingCounter containerClassName={styles.billingCounter} />
      </OutsideRoomLayout>
      {showRoomTypeModal && (
        <RoomTypeModal
          onSubmit={launchNewRoom}
          close={handleCloseRoomTypeModal}
        />
      )}
      {!agreedToTerms && <TermsModal />}
      {showInviteModal && <InviteModal url={fullUrl} />}
      {showUpgradeModal && <UpgradeModal />}
      {shouldShowPaywall && (
        <Overlay closeOverlay={hidePaywall} className={styles.paywall} />
      )}
    </>
  );
};

export default DemoStart;
