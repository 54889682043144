import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  useUpdateLastActiveTimeMutation,
  useUpsertUserMutation,
} from "generated/graphql";
import {
  selectDecodedAuthToken,
  selectEmail,
  selectOrganizationId,
  selectUserId,
  selectUserJoinTimestamp,
  setUserFirstName,
  setUserJoinTimestamp,
} from "redux/userRedux";

import DemoStart from "pages/DemoStart/DemoStart";
import { useTrackEvent } from "utils/metricsUtils";
import {
  setAudioProviderOnly,
  setVideoConferencing,
} from "redux/settingsRedux";
import { useAutomaticLogout } from "pages/Space/hooks/useAutomaticLogout";
import { useAppSignOut } from "utils/appSignOutUtils";
import { getIsInternal } from "utils/signInUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { backendRequest } from "utils/backendRequest";

function Home() {
  const userId = useSelector(selectUserId);
  const email = useSelector(selectEmail);
  const organizationId = useSelector(selectOrganizationId);
  const appSignOut = useAppSignOut();
  const [updateLastActivityTime] = useUpdateLastActiveTimeMutation();
  const onActivity = () => {
    if (userId) {
      updateLastActivityTime({
        variables: {
          userId,
          activeTimestamp: new Date().toISOString(),
        },
      }).catch(logUnexpectedError);
    }
  };
  useAutomaticLogout(appSignOut, onActivity);

  const [upsertUserMutation] = useUpsertUserMutation();
  const dispatch = useDispatch();
  const { trackEvent } = useTrackEvent();
  const decodedAuthToken = useSelector(selectDecodedAuthToken);
  const userJoinTimestamp = useSelector(selectUserJoinTimestamp);

  const logSignUp = () => {
    trackEvent("Sign Up");
    sendSignUpNotification();
  };

  useEffect(() => {
    const internal = async () => {
      try {
        // Upsert the user into the DB
        if (userId && decodedAuthToken) {
          const now = new Date().toISOString();
          const isInternal = getIsInternal(decodedAuthToken);
          const { data } = await upsertUserMutation({
            variables: {
              userId,
              email,
              organizationId,
              joinTimestamp: now,
              isInternal,
            },
          });
          localStorage.setItem("isInternal", isInternal ? "true" : "false");
          const audioProviderOnly =
            data?.insert_provider_settings_one?.audio_provider_only;
          dispatch(setAudioProviderOnly(audioProviderOnly));
          const videoConferencing =
            data?.insert_provider_settings_one?.video_conferencing;
          dispatch(setVideoConferencing(videoConferencing));
          dispatch(setUserFirstName(data?.insert_user_one?.first_name || null));
          const userJoinTimestamp = data?.insert_user_one?.join_timestamp;
          if (userJoinTimestamp) {
            dispatch(setUserJoinTimestamp(userJoinTimestamp));
            if (Date.parse(userJoinTimestamp) === Date.parse(now)) {
              logSignUp();
            }
          }
        }
      } catch (error) {
        alert("Internal error, please try again later.");
        logUnexpectedError(error);
      }
    };
    internal();
  }, [userId, upsertUserMutation, decodedAuthToken]);

  const sendSignUpNotification = () => {
    backendRequest({
      path: "/sign-up-notification",
      options: {
        method: "POST",
        keepalive: true, // make sure the request is sent completely even once the page is destroyed
      },
    });
  };

  const getLocalISOString = () => {
    const date = new Date();
    const timezoneOffset = -date.getTimezoneOffset();
    const diff = timezoneOffset >= 0 ? "+" : "-";
    const pad = (n: number) => `${Math.floor(Math.abs(n))}`.padStart(2, "0");
    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds()) +
      diff +
      pad(timezoneOffset / 60) +
      ":" +
      pad(timezoneOffset % 60)
    );
  };

  const logPageVisit = () => {
    // Log page visit
    const localTime = getLocalISOString();
    backendRequest({
      path: "/stats-visit",
      searchParams: { time: localTime },
      options: {
        method: "POST",
        keepalive: true, // make sure the request is sent completely even once the page is destroyed
      },
    });
  };

  useEffect(() => {
    if (userJoinTimestamp) {
      trackEvent("Home Page Visit");
      logPageVisit();
    }
  }, [userJoinTimestamp]);

  return <DemoStart />;
}

export default Home;
