import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetExceptMeetingID,
  resetNavigation,
  selectMeetingID,
} from "redux/spaceNavigationRedux";
import mixpanel from "mixpanel-browser";
import { backendRequest } from "utils/backendRequest";
import { getAddBreadcrumb, logUnexpectedError } from "utils/errorUtils";
import {
  selectEncodedAuthToken,
  selectEncodedClientToken,
  setClientId,
} from "redux/userRedux";
import {
  pushWaitingRoomAlertClient,
  removeWaitingRoomAlertClient,
  setClientHasJoinedRoom,
  setCurrentClient,
} from "redux/clientManagementRedux";
import { useIsTherapist } from "pages/Space/hooks/useIsTherapist";
import { useAnalyticsInfo } from "utils/metricsUtils";
import { io, Socket } from "socket.io-client";
import { useBoxRef } from "hooks/useBoxRef";
import { resetEditRoomNavigation } from "redux/editRoomNavigationRedux";
import { selectSessionEHRSystem } from "redux/ehrSystemRedux";
import { useRefetchWaitingRoom } from "utils/waitingRoomUtils";
import { couldBeMobileIOS } from "pages/Space/hooks/connection/connectionUtils";

export type SessionState =
  | "connecting"
  | "connected"
  | "session-ended"
  | "resetting";

export interface ClientToGatewayEvents {
  "register participant": (
    meetingID: string,
    roleString: "therapist" | "client",
    encodedAuthToken: string | undefined,
    analyticsInfo: Awaited<ReturnType<ReturnType<typeof useAnalyticsInfo>>>
  ) => void;
  activity: (meetingID: string, roleString: "therapist" | "client") => void;
  "update analytics info": (
    meetingID: string,
    roleString: "therapist" | "client",
    analyticsInfo: Awaited<ReturnType<ReturnType<typeof useAnalyticsInfo>>>
  ) => void;
}

export type GatewayToClientError =
  | "CLIENT_REGISTRATION_NOT_AUTHORIZED"
  | string;

export interface GatewayToClientEvents {
  connect: () => void;
  "waiting room - new client": (secretClientId: string) => void;
  "waiting room - client left": (secretClientId: string) => void;
  containerId: (
    newContainerInstance: string,
    newContainerId: string,
    livekitToken: string
  ) => void;
  "session ended": () => void;
  close: () => void;
  reregistered: () => void;
  "client joined": (clientId: string) => void;
  "last client left": () => void;
  "new room opened": () => void;
  error: (errorCode: GatewayToClientError) => void;
}

export type GatewaySocketType = Socket<
  GatewayToClientEvents,
  ClientToGatewayEvents
>;

type GatewayConnectionContextProps = {
  gatewaySocket: GatewaySocketType;
  roomContainer: { containerInstance: string; containerId: string } | undefined;
  livekitToken: string | undefined;
  sessionState: SessionState;
  // TODO: type properly the possible error messages
  joinRoomError: string | undefined;
  endSession: () => void;
  leave: (message?: string) => void;
  setOnTrackSessionEnd: (callback: () => void) => void;
  setOnTrackRoomClose: (callback: () => void) => void;
};

type GatewayConnectionContextProviderProps = {
  children: ReactNode;
};

const gatewayServerAddress = process.env.REACT_APP_SIGNALING_SERVER || "";

const addBreadcrumb = getAddBreadcrumb("connections");

const GatewayConnectionContext = createContext<
  GatewayConnectionContextProps | undefined
>(undefined);

export const GatewayConnectionProvider = ({
  children,
}: GatewayConnectionContextProviderProps) => {
  const meetingID = useSelector(selectMeetingID);
  const isTherapist = useIsTherapist();
  const ehrSystem = useSelector(selectSessionEHRSystem);
  // TODO: move all waiting room events to a separated file
  const refetchWaitingRoom = useRefetchWaitingRoom();

  const dispatch = useDispatch();

  const [sessionState, setSessionState] = useState<SessionState>("connecting");

  const gatewaySocketRef = useRef<GatewaySocketType>(
    io(gatewayServerAddress, {
      transports: ["websocket", "polling"],
      path: `/socket.io`,
      withCredentials: true,
      reconnectionDelayMax: 1500,
      timeout: 3000,
      autoConnect: false,
    })
  );

  const [joinRoomError, setJoinRoomError] = useState<string | undefined>();
  const joinRoomErrorRef = useBoxRef(joinRoomError);

  const [roomContainer, setRoomContainer] = useState<
    { containerInstance: string; containerId: string } | undefined
  >(undefined);

  const [livekitToken, setLivekitToken] = useState<string | undefined>();

  const onTrackSessionEndRef = useRef<() => void>();
  const onTrackRoomCloseRef = useRef<() => void>();

  const emitRegisterParticipant = useEmitRegisterParticipant(
    gatewaySocketRef.current
  );

  const updateParticipantStatusOnGateway = async (joinedStatus: boolean) => {
    const mixpanelUserId = mixpanel.get_distinct_id();
    return await backendRequest({
      path: `/rooms/${meetingID}/participant`,
      searchParams: { mixpanelUserId },
      options: {
        method: "POST",
        body: JSON.stringify({ joinedStatus }),
        headers: {
          "Content-Type": "text/plain",
        },
        keepalive: true, // make sure the request is sent completely even once the page is destroyed
      },
    }).catch(logUnexpectedError); // Firefox aborts and throws an error if navigating away from a page before the response is received;
  };

  const joinRoomOnGateway = async () => {
    const participantResult = await updateParticipantStatusOnGateway(true);

    const participantResultJson = await participantResult
      ?.json()
      .catch(logUnexpectedError);
    const containerInstance = participantResultJson?.containerInstance;
    const containerId = participantResultJson?.containerId;
    const livekitToken = participantResultJson?.livekitToken;
    const clientHasJoinedResponse = participantResultJson?.clientHasJoinedRoom;
    const clientId = participantResultJson?.clientId;
    const error = participantResultJson?.error;

    if (
      !participantResult ||
      !participantResultJson ||
      participantResult.status !== 200 ||
      error
    ) {
      let externalErrorMessage;
      if (error === "No such meeting.") {
        if (isTherapist) {
          externalErrorMessage =
            "Error: This room link is invalid or has been closed. Please check that the url is correct, or open the room again.";
        } else {
          externalErrorMessage =
            "Error: This room link is invalid or has been closed. Please check that the url is correct.";
        }
      } else if (error === "there is already a therapist in the room") {
        externalErrorMessage =
          "Error: A provider has already connected to this room.";
      } else if (error === "there is already a client in the room") {
        externalErrorMessage = "Error: This room is already in use.";
      } else if (error === "invalid") {
        externalErrorMessage = isTherapist
          ? "Error: This room belongs to another therapist. Try signing out or using an Incognito window if you'd like to join as a client."
          : "Error: You are not authorized to join this room.";
      } else {
        const status = participantResult
          ? participantResult.status
          : "No result";
        const message = `Error: updateParticipantStatus returned <${status}> with error <${error}>`;
        logUnexpectedError(message);
        externalErrorMessage = "Error: Internal error. Please try again later.";
      }

      return { connectionError: externalErrorMessage };
    }

    if (isTherapist) {
      dispatch(setClientHasJoinedRoom(!!clientHasJoinedResponse));
      if (clientHasJoinedResponse) {
        dispatch(setClientId(clientId));
      } else {
        dispatch(setClientId(undefined));
      }
    }

    if (!containerId || !containerInstance) {
      logUnexpectedError(
        "Error: Rooms Manager joinRoom did not return both a containerId and containerInstance"
      );
      return {
        connectionError: "Error: Internal error. Please try again later.",
      };
    }
    if (!livekitToken) {
      logUnexpectedError(
        "Error: Rooms Manager joinRoom did not return a livekitToken"
      );
      return {
        connectionError: "Error: Internal error. Please try again later.",
      };
    }
    return { containerInstance, containerId, livekitToken };
  };

  // Initialize first layer of connections (gateway http request and socket connection)
  useEffect(() => {
    if (!meetingID) {
      logUnexpectedError("Error: meetingID is not defined");
      return;
    }
    const initializeConnections = async () => {
      const {
        containerInstance,
        containerId,
        livekitToken,
        connectionError: joinRoomError,
      } = await joinRoomOnGateway();
      if (joinRoomError) {
        setJoinRoomError(joinRoomError);
        logUnexpectedError(joinRoomError);
        return;
      }

      gatewaySocketRef.current.connect();
      await emitRegisterParticipant();
      setRoomContainer({ containerInstance, containerId });
      setLivekitToken(livekitToken);
      setSessionState("connected");
    };
    initializeConnections();
    return () => {
      gatewaySocketRef.current.disconnect();
    };
  }, [meetingID]);

  const leaveRoomOnGateway = () => {
    updateParticipantStatusOnGateway(false).catch(logUnexpectedError);
  };

  const sendLeaveSignals = () => {
    addBreadcrumb("info", "sendLeaveSignals", {
      hasConnectionError: joinRoomErrorRef.current,
    });
    if (joinRoomErrorRef.current) {
      return;
    }
    onTrackSessionEndRef.current?.();
    onTrackRoomCloseRef.current?.();

    // TODO: make this more robust to other errors to ensure that it doesn't kick out another
    // client that has joined
    leaveRoomOnGateway();
  };

  useEffect(() => {
    const beforeLeavePage = () => {
      try {
        mixpanel.set_config({ api_transport: "sendBeacon" }); // see https://github.com/mixpanel/mixpanel-js/issues/184
        sendLeaveSignals();
        closeAllConnections();
        return null;
      } catch (err) {
        logUnexpectedError(err);
      }
    };

    if (couldBeMobileIOS) {
      window.onpagehide = beforeLeavePage;
    } else {
      window.onbeforeunload = beforeLeavePage;
    }
  }, []);

  const closeAllConnections = () => {
    setRoomContainer(undefined);
    setLivekitToken(undefined);
    gatewaySocketRef.current.disconnect();
  };

  useRawGatewayEventListener(gatewaySocketRef.current, "connect", () => {
    refetchWaitingRoom().catch(logUnexpectedError);
  });

  useRawGatewayEventListener(
    gatewaySocketRef.current,
    "waiting room - new client",
    (secretClientId) => {
      dispatch(pushWaitingRoomAlertClient(secretClientId));
      refetchWaitingRoom().catch(logUnexpectedError);
    }
  );

  useRawGatewayEventListener(
    gatewaySocketRef.current,
    "waiting room - client left",
    (secretClientId) => {
      dispatch(removeWaitingRoomAlertClient(secretClientId));
      refetchWaitingRoom().catch(logUnexpectedError);
    }
  );

  useRawGatewayEventListener(
    gatewaySocketRef.current,
    "containerId",
    (
      newContainerInstance: string,
      newContainerId: string,
      livekitToken: string
    ) => {
      try {
        addBreadcrumb("info", "onNewRoomContainer", {
          newContainerInstance,
          newContainerId,
          livekitToken,
        });
        // TODO: ensure we set the sessionState appropriately
        // setIsResettingTheRoom(true);
        onTrackSessionEndRef.current?.();
        dispatch(resetNavigation());
        // we don't need to call closeRoomConnections because
        // changing the roomContainer and livekitToken will close them
        // and create a new one immediately
        // closeRoomConnections();
        // setIsConnectingToRemote(true);
        setRoomContainer({
          containerInstance: newContainerInstance,
          containerId: newContainerId,
        });
        setLivekitToken(livekitToken);
        setSessionState("connected");
      } catch (err) {
        logUnexpectedError(err);
      }
    }
  );

  useRawGatewayEventListener(gatewaySocketRef.current, "session ended", () => {
    addBreadcrumb("info", "onSessionEnded");
    try {
      onTrackSessionEndRef.current?.();
      setSessionState("session-ended");
      dispatch(resetExceptMeetingID());
      dispatch(resetEditRoomNavigation());
      closeAllConnections();
    } catch (err) {
      logUnexpectedError(err);
    }
  });

  useRawGatewayEventListener(gatewaySocketRef.current, "close", () => {
    addBreadcrumb("info", "closeRoomConnections");
    try {
      closeAllConnections();
    } catch (err) {
      logUnexpectedError(err);
    }
  });

  useRawGatewayEventListener(gatewaySocketRef.current, "reregistered", () => {
    setJoinRoomError(
      "Disconnected. You may have opened this Teleo room in another tab."
    );
  });

  useRawGatewayEventListener(
    gatewaySocketRef.current,
    "client joined",
    (clientId) => {
      dispatch(setClientId(clientId));
      dispatch(setClientHasJoinedRoom(true));
    }
  );

  useRawGatewayEventListener(
    gatewaySocketRef.current,
    "last client left",
    () => {
      dispatch(setClientHasJoinedRoom(false));
      dispatch(setClientId(undefined));
      if (!ehrSystem) {
        dispatch(setCurrentClient(undefined));
      }
    }
  );

  useRawGatewayEventListener(
    gatewaySocketRef.current,
    "new room opened",
    () => {
      setJoinRoomError("Room closed: You've opened a room in another tab.");
    }
  );

  useRawGatewayEventListener(gatewaySocketRef.current, "error", (errorCode) => {
    logUnexpectedError(errorCode);
    if (errorCode === "CLIENT_REGISTRATION_NOT_AUTHORIZED") {
      setJoinRoomError("Session ended.");
    } else {
      setJoinRoomError(`Internal error: ${errorCode}. Please try again later.`);
    }
  });

  const endSession = useCallback(async () => {
    setSessionState("resetting");
    setLivekitToken(undefined);

    // After this api call, the gateway will emit "containerId" event with the new container data
    await backendRequest({
      path: `/rooms/${meetingID}/end`,
      options: {
        method: "POST",
      },
    }).catch(logUnexpectedError);
  }, []);

  const leave = useCallback((message?: string) => {
    addBreadcrumb("info", "leave", { message });
    try {
      sendLeaveSignals();
      closeAllConnections();
      setJoinRoomError(message || "Disconnected.");
      addBreadcrumb(
        "debug",
        "Left room. Setting Disconnected connection error message."
      );
    } catch (err) {
      logUnexpectedError(err);
    }
  }, []);

  const setOnTrackSessionEnd = (callback: () => void) => {
    onTrackSessionEndRef.current = callback;
  };

  const setOnTrackRoomClose = (callback: () => void) => {
    onTrackRoomCloseRef.current = callback;
  };

  const contextValue = useMemo<GatewayConnectionContextProps>(
    () => ({
      gatewaySocket: gatewaySocketRef.current,
      roomContainer,
      livekitToken,
      sessionState,
      joinRoomError,
      endSession,
      leave,
      setOnTrackSessionEnd,
      setOnTrackRoomClose,
    }),
    [
      roomContainer,
      livekitToken,
      sessionState,
      joinRoomError,
      endSession,
      leave,
      setOnTrackSessionEnd,
      setOnTrackRoomClose,
    ]
  );

  return (
    <GatewayConnectionContext.Provider value={contextValue}>
      {children}
    </GatewayConnectionContext.Provider>
  );
};

/**
 * Custom hook to access the GatewayConnectionContext.
 *
 * This hook provides a convenient way to access the context value of
 * GatewayConnectionContext.
 *
 * This hook should not be used in other files.
 * Instead, create a custom hook to export the intended property or functionality.
 *
 * @return The current context value of GatewayConnectionContext.
 */
const useGatewayConnectionCtx = () => {
  return useContext(GatewayConnectionContext)!;
};

export const useRoomContainerInfo = () => {
  const { roomContainer: roomContainerInfo } = useGatewayConnectionCtx();
  return roomContainerInfo;
};

export const useLivekitToken = () => {
  const { livekitToken } = useGatewayConnectionCtx();
  return livekitToken;
};

const useRawGatewayEventListener = <
  Ev extends keyof GatewayToClientEvents & string
>(
  gatewaySocket: GatewaySocketType,
  ev: Ev,
  listener: GatewayToClientEvents[Ev]
) => {
  useEffect(() => {
    // @ts-ignore
    gatewaySocket.on(ev, listener);
    return () => {
      // @ts-ignore
      gatewaySocket.off(ev, listener);
    };
  }, [gatewaySocket, ev, listener]);
};

export const useGatewayEventListener = <
  Ev extends keyof GatewayToClientEvents & string
>(
  ev: Ev,
  listener: GatewayToClientEvents[Ev]
) => {
  const { gatewaySocket } = useGatewayConnectionCtx();

  useRawGatewayEventListener(gatewaySocket, ev, listener);
};

export const useEmitRegisterParticipant = (
  gatewaySocket: GatewaySocketType
) => {
  const meetingID = useSelector(selectMeetingID);
  const isTherapist = useIsTherapist();

  const encodedAuthToken = useSelector(selectEncodedAuthToken);
  const encodedClientToken = useSelector(selectEncodedClientToken);
  const encodedClientTokenRef = useBoxRef(encodedClientToken);
  const getAnalyticsInfo = useAnalyticsInfo();

  return async () => {
    if (!meetingID) {
      logUnexpectedError("Meeting ID not found");
      return;
    }
    const roleString = isTherapist ? "therapist" : "client";
    const analyticsInfo = await getAnalyticsInfo();
    gatewaySocket.emit(
      "register participant",
      meetingID,
      roleString,
      encodedAuthToken || encodedClientTokenRef.current,
      analyticsInfo
    );
  };
};

export const useEmitActivitySignal = () => {
  const meetingID = useSelector(selectMeetingID);
  const isTherapist = useIsTherapist();
  const { gatewaySocket } = useGatewayConnectionCtx();

  return () => {
    if (!meetingID) {
      logUnexpectedError("Meeting ID not found");
      return;
    }
    const roleString = isTherapist ? "therapist" : "client";
    gatewaySocket.emit("activity", meetingID, roleString);
  };
};

export const useEmitUpdateAnalyticsInfo = () => {
  const { gatewaySocket } = useGatewayConnectionCtx();
  const meetingID = useSelector(selectMeetingID);
  const isTherapist = useIsTherapist();

  return (
    analyticsInfo: Awaited<ReturnType<ReturnType<typeof useAnalyticsInfo>>>
  ) => {
    if (!meetingID) {
      logUnexpectedError("Meeting ID not found");
      return;
    }
    const roleString = isTherapist ? "therapist" : "client";
    gatewaySocket.emit(
      "update analytics info",
      meetingID,
      roleString,
      analyticsInfo
    );
  };
};

export const useOnTrackSessionEnd = (callback: () => void) => {
  const { setOnTrackSessionEnd } = useGatewayConnectionCtx();
  setOnTrackSessionEnd(callback);
};

export const useOnTrackRoomClose = (callback: () => void) => {
  const { setOnTrackRoomClose } = useGatewayConnectionCtx();
  setOnTrackRoomClose(callback);
};

export const useEndSession = () => {
  const { endSession } = useGatewayConnectionCtx();
  return () => {
    addBreadcrumb("info", "endSession");
    // TODO: move this hook to a separated file and call both room and gateway endSession functions
    // endSessionOnRemoteSocket();
    endSession();
  };
};

export const useLeave = () => {
  const { leave } = useGatewayConnectionCtx();
  return leave;
};

export const useJoinRoomError = () => {
  const { joinRoomError } = useGatewayConnectionCtx();
  return joinRoomError;
};

export const useSessionState = () => {
  const { sessionState } = useGatewayConnectionCtx();
  return sessionState;
};
