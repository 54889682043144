import React, { MouseEventHandler, useEffect } from "react";
import clsx from "clsx";

import styles from "./Overlay.module.css";

type ModalProps = {
  closeOverlay?: () => void;
  className?: string;
  children?: React.ReactNode;
};

const Overlay = ({ closeOverlay, className, children }: ModalProps) => {
  const mouseDownElement = React.useRef<HTMLElement | null>(null);

  useEffect(() => {
    const mousedown = (ev: MouseEvent) => {
      mouseDownElement.current = ev.target as HTMLElement;
    };
    document.addEventListener("pointerdown", mousedown);

    return () => {
      document.removeEventListener("pointerdown", mousedown);
    };
  }, []);

  const handleOverlayClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.target === mouseDownElement.current) {
      if (closeOverlay) {
        closeOverlay();
      }
    }
  };

  return (
    <div
      className={clsx(styles.overlay, className)}
      onClick={handleOverlayClick}
    >
      {children}
    </div>
  );
};

export default Overlay;
