import { createSlice } from "@reduxjs/toolkit";

export enum UserRole {
  THERAPIST,
  CLIENT,
}

export interface UserState {
  encodedAuthToken?: string;
  decodedAuthToken?: any;
  signInExpires?: number;
  encodedClientToken?: string;
  clientPreAdmitToken?: string;
  currentRoomId?: string;
  role: UserRole | null;
  firstName?: string;
  userJoinTimestamp?: string; // ISO 8601 format
  providerIsInternal?: boolean;
  providerId?: string;
  clientId?: string;
  organizationId?: string;
  signedOut: boolean;
}

export interface RootState {
  user: UserState;
}

export const userSlice = createSlice({
  name: "user",
  initialState: { role: null, signedOut: false } as UserState,
  reducers: {
    setEncodedAuthToken: (state, value) => {
      state.encodedAuthToken = value.payload;
    },
    setDecodedAuthToken: (state, value) => {
      state.decodedAuthToken = value.payload;
    },
    setSignInExpires: (state, value) => {
      state.signInExpires = value.payload;
    },
    setEncodedClientToken: (state, value) => {
      state.encodedClientToken = value.payload;
    },
    setClientPreAdmitToken: (state, value) => {
      state.clientPreAdmitToken = value.payload;
    },
    setCurrentRoomId: (state, value) => {
      state.currentRoomId = value.payload;
    },
    setUserRole: (state, value) => {
      state.role = value.payload;
    },
    setUserFirstName: (state, value) => {
      state.firstName = value.payload;
    },
    setUserJoinTimestamp: (state, value) => {
      state.userJoinTimestamp = value.payload;
    },
    setProviderIsInternal: (state, value) => {
      state.providerIsInternal = value.payload;
    },
    setProviderId: (state, value) => {
      state.providerId = value.payload;
    },
    setClientId: (state, value) => {
      state.clientId = value.payload;
    },
    setOrganizationId: (state, value) => {
      state.organizationId = value.payload;
    },
    signOut: (state) => {
      state.signedOut = true;
    },
  },
});

export const {
  setEncodedAuthToken,
  setDecodedAuthToken,
  setSignInExpires,
  setEncodedClientToken,
  setClientPreAdmitToken,
  setCurrentRoomId,
  setUserRole,
  setUserFirstName,
  setUserJoinTimestamp,
  setProviderIsInternal,
  setProviderId,
  setClientId,
  setOrganizationId,
  signOut,
} = userSlice.actions;

export const selectUserId = (state: RootState) =>
  state.user.decodedAuthToken?.userId;
export const selectEmail = (state: RootState) =>
  state.user.decodedAuthToken?.email || "";
export const selectEncodedAuthToken = (state: RootState) =>
  state.user.encodedAuthToken;
export const selectDecodedAuthToken = (state: RootState) =>
  state.user.decodedAuthToken;
export const selectSignInExpires = (state: RootState) =>
  state.user.signInExpires;
export const selectEncodedClientToken = (state: RootState) =>
  state.user.encodedClientToken;
export const selectClientPreAdmitToken = (state: RootState) =>
  state.user.clientPreAdmitToken;
export const selectCurrentRoomId = (state: RootState) =>
  state.user.currentRoomId;
export const selectUserRole = (state: RootState) => state.user.role;
export const selectUserFirstName = (state: RootState) => state.user.firstName;
export const selectUserJoinTimestamp = (state: RootState) =>
  state.user.userJoinTimestamp;
export const selectProviderIsInternal = (state: RootState) =>
  state.user.providerIsInternal;
export const selectProviderId = (state: RootState) => state.user.providerId;
export const selectClientId = (state: RootState) => state.user.clientId;
export const selectOrganizationId = (state: RootState) =>
  state.user.organizationId;
export const selectSignedOut = (state: RootState) => state.user.signedOut;

export default userSlice.reducer;
