import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  selectClientPreAdmitToken,
  selectEncodedAuthToken,
  selectEncodedClientToken,
  selectUserRole,
  setClientPreAdmitToken,
  setEncodedClientToken,
  UserRole,
} from "redux/userRedux";

import { ApolloProvider } from "@apollo/client";

import { createApolloClient } from "utils/dbUtils";
import Space from "./Space";
import Button from "components/Button/Button";
import checkIcon from "assets/icons/check.svg";
import styles from "./OuterSpace.module.css";
import { useTrackEvent } from "utils/metricsUtils";
import { resetNavigation, selectMeetingID } from "redux/spaceNavigationRedux";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import { resetEditRoomNavigation } from "redux/editRoomNavigationRedux";
import TextInput from "components/TextInput/TextInput";
import io from "socket.io-client";
import { Link } from "react-router-dom";
import clsx from "clsx";
import OutsideRoomLayout from "components/OutsideRoomLayout/OutsideRoomLayout";
import { isMobileDevice } from "utils/deviceUtils";
import useSound from "use-sound";
import notificationSound from "assets/sounds/notification.mp3";
import { useBoxRef } from "hooks/useBoxRef";
import { useFlashTitle } from "./hooks/useFlashTitle";
import { useLiveKitTest } from "./hooks/useLiveKitTest";
import { SubscriptionContextProvider } from "components/SubscriptionContext/SubsciptionContext";

type OuterSpaceProps = {
  loading: boolean;
  roomIsValid?: boolean;
  roomIsOpen?: boolean;
  startMeeting?: () => void;
  startMeetingTrigger: "onLoad" | "onInteract";
  signOut: () => void;
};

const OuterSpace = ({
  loading,
  roomIsValid,
  roomIsOpen,
  startMeeting,
  startMeetingTrigger,
  signOut,
}: OuterSpaceProps) => {
  const meetingID = useSelector(selectMeetingID);
  const [startMeetingCalled, setStartMeetingCalled] = useState<boolean>(false);
  const encodedAuthToken = useSelector(selectEncodedAuthToken);
  const encodedAuthTokenRef = useRef(encodedAuthToken); // for callback
  useEffect(() => {
    encodedAuthTokenRef.current = encodedAuthToken;
  }, [encodedAuthToken]);
  const clientPreAdmitToken = useSelector(selectClientPreAdmitToken);
  const encodedClientToken = useSelector(selectEncodedClientToken);
  const encodedClientTokenRef = useRef(encodedClientToken); // for callback
  useEffect(() => {
    encodedClientTokenRef.current = encodedClientToken;
  }, [encodedClientToken]);
  const userRole = useSelector(selectUserRole);
  const isProvider = userRole === UserRole.THERAPIST;
  const [name, setName] = useState("");
  // Currently the organizationClientId is only used to control if the participant name is editable
  const [organizationClientId, setOrganizationClientId] = useState("");
  const [isInWaitingRoom, setIsInWaitingRoom] = useState(false);

  const [loadingAddToWaitingRoom, setLoadingAddToWaitingRoom] = useState(false);

  const wasAdmitted = useRef(false);

  useLiveKitTest();

  const [playNotificationSound] = useSound(notificationSound);
  const playNotificationSoundRef = useBoxRef(playNotificationSound);
  const triggerTitleFlashing = useFlashTitle(
    "Session starting - Teleo",
    "Client admission"
  );

  useEffect(() => {
    if (
      startMeetingTrigger === "onLoad" &&
      !startMeetingCalled &&
      startMeeting
    ) {
      setStartMeetingCalled(true);
      startMeeting();
    }
  }, [startMeetingTrigger, startMeeting, startMeetingCalled]);

  const [client] = useState(
    createApolloClient(encodedAuthTokenRef, encodedClientTokenRef)
  );

  const maybeGoFullscreen = () => {
    const isMobile = isMobileDevice();
    if (isMobile && document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    }
  };

  const { trackEvent } = useTrackEvent();
  const dispatch = useDispatch();

  const setDefaultPatientInfo = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const organizationClientIdParam = urlParams.get("ocid");
    const clientNameParam = urlParams.get("cn");

    if (organizationClientIdParam && clientNameParam) {
      setOrganizationClientId(organizationClientIdParam);
      setName(clientNameParam);
    }
  };

  useEffect(() => {
    trackEvent("Load - Outer Space");

    setDefaultPatientInfo();

    return () => {
      dispatch(resetNavigation());
      dispatch(resetEditRoomNavigation());
    };
  }, []);

  // Add a button to require interaction from client or therapist who refreshed the page (so video stream will play)
  const [hasInteracted, setHasInteracted] = useState(!!encodedAuthToken);
  const onInteract = () => {
    setHasInteracted(true);
    maybeGoFullscreen();
  };

  const onEnterRoomClick = async () => {
    if (!startMeetingCalled && startMeeting) {
      setStartMeetingCalled(true);
      await startMeeting();
      setHasInteracted(true);
    }
    maybeGoFullscreen();
  };

  const addToWaitingRoom = () => {
    setLoadingAddToWaitingRoom(true);
    const server = process.env.REACT_APP_SIGNALING_SERVER || "";
    const socket = io(server, {
      transports: ["websocket", "polling"],
      path: `/socket.io`,
      withCredentials: true,
      reconnectionDelayMax: 1500,
      timeout: 3000,
    });

    const processJoined = () => {
      setIsInWaitingRoom(true);
      setLoadingAddToWaitingRoom(false);
    };

    const processAdmitted = (encodedClientToken: string) => {
      dispatch(setEncodedClientToken(encodedClientToken));
      setHasInteracted(true);
      wasAdmitted.current = true;
      playNotificationSoundRef.current();
      triggerTitleFlashing();
      socket.close();
    };

    const processDismissed = () => {
      dispatch(setClientPreAdmitToken(undefined));
      setIsInWaitingRoom(false);
      setStartMeetingCalled(false);
      alert("You are no longer in the waiting room.");
    };

    const processDisconnected = () => {
      if (!wasAdmitted.current) {
        alert("Disconnected. You are no longer in the waiting room.");
        setIsInWaitingRoom(false);
      }
    };

    socket.on("waiting room - joined", processJoined);
    socket.on("waiting room - admitted", processAdmitted);
    socket.on("waiting room - dismissed", processDismissed);
    socket.on("disconnect", processDisconnected);

    socket.emit("waiting room - join", meetingID, name, clientPreAdmitToken);
    maybeGoFullscreen();
  };

  useEffect(() => {
    if (
      startMeetingTrigger === "onInteract" &&
      !isInWaitingRoom &&
      !loadingAddToWaitingRoom &&
      clientPreAdmitToken
    ) {
      addToWaitingRoom();
    }
  }, [
    startMeetingTrigger,
    isInWaitingRoom,
    loadingAddToWaitingRoom,
    clientPreAdmitToken,
  ]);

  if (startMeetingTrigger === "onInteract" && !startMeetingCalled) {
    return (
      <OutsideRoomLayout>
        <Button className={styles.button} onClick={onEnterRoomClick}>
          {"Enter Room"}
        </Button>
      </OutsideRoomLayout>
    );
  }

  if (
    loading ||
    (startMeetingTrigger === "onInteract" && loadingAddToWaitingRoom)
  ) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingAnimation />
      </div>
    );
  }

  if (hasInteracted && (isProvider || wasAdmitted.current)) {
    return (
      <ApolloProvider client={client}>
        <SubscriptionContextProvider>
          <Space />
        </SubscriptionContextProvider>
      </ApolloProvider>
    );
  }

  if (!roomIsValid) {
    return (
      <OutsideRoomLayout>
        <div className={styles.text}>
          This room does not exist. Please check the link and try again.
        </div>
      </OutsideRoomLayout>
    );
  }

  if (isProvider) {
    return (
      <ApolloProvider client={client}>
        <OutsideRoomLayout>
          {roomIsOpen ? (
            <Button className={styles.button} onClick={onInteract}>
              {"Enter Room"}
            </Button>
          ) : (
            <>
              <div className={clsx(styles.text, styles.bottomMargin)}>
                This room link belongs to another provider.
                <br />
                To join as a client, please sign out or open this link in an
                incognito tab.
              </div>
              <Button onClick={signOut} secondary>
                {"Sign Out"}
              </Button>
            </>
          )}
        </OutsideRoomLayout>
      </ApolloProvider>
    );
  }

  // is client
  return (
    <ApolloProvider client={client}>
      <OutsideRoomLayout>
        {isInWaitingRoom ? (
          <div className={styles.thankYouRow}>
            <img className={styles.check} src={checkIcon} alt={"Check"} />
            <div className={styles.text}>
              Thank you! Your provider will let you in shortly.
            </div>
            <LoadingAnimation isSmall />
          </div>
        ) : (
          <>
            {!organizationClientId && (
              <>
                <div className={styles.text}>Your full name:</div>
                <TextInput
                  className={styles.nameInput}
                  value={name}
                  setValue={setName}
                  placeholder={""}
                  maxLength={150}
                  autoFocus
                />
              </>
            )}
            <Button
              className={clsx(styles.button, {
                [styles.topMargin]: !!organizationClientId,
              })}
              onClick={addToWaitingRoom}
              disabled={!name || loadingAddToWaitingRoom}
            >
              {"Enter Waiting Room"}
            </Button>
            {!organizationClientId && (
              <div className={styles.smallText}>
                If you are a provider,{" "}
                <Link to={`https://${process.env.REACT_APP_HOME_URL}`}>
                  sign in
                </Link>
                .
              </div>
            )}
          </>
        )}
      </OutsideRoomLayout>
    </ApolloProvider>
  );
};

export default OuterSpace;
