import React from "react";

import styles from "./CenteredLoadingDiv.module.css";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";

type CenteredLoadingDivProps = { isLight?: boolean };

const CenteredLoadingDiv = ({ isLight }: CenteredLoadingDivProps) => {
  return (
    <div className={styles.loadingContainer}>
      <LoadingAnimation isLight={isLight} />
    </div>
  );
};

export default CenteredLoadingDiv;
