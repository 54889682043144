import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  LiveKitRoom,
  useConnectionState,
  useDataChannel,
  useLocalParticipant,
  useParticipantTracks,
  useRemoteParticipants,
  setLogLevel,
  useConnectionQualityIndicator,
  useMaybeRoomContext,
} from "@livekit/components-react";
import type { ReceivedDataMessage } from "@livekit/components-core";
import {
  DataPublishOptions,
  DisconnectReason,
  MediaDeviceFailure,
  Participant,
  RemoteParticipant,
  Track,
} from "livekit-client";
import mixpanel from "mixpanel-browser";
import { v4 as uuidv4 } from "uuid";
import { logUnexpectedError } from "utils/errorUtils";
import EventEmitter from "events";
import { INITIAL_VIDEO_PRESET } from "./useLocalUserMedia";
import { useLivekitToken } from "./GatewayConnectionContext";

export type TeleoPeer = Participant;

type WebRTCContextProviderProps = {
  children: ReactNode;
};
const serverUrl = "wss://teleo-2rkw03ph.livekit.cloud";
export const ROOM_LIVEKIT_PARTICIPANT_ID = "room-gst-producer";
// Keeping it under 15KiB as detailed in LiveKit's documentation
// https://docs.livekit.io/home/client/data/messages/#size-limits
const MAX_MESSAGE_SIZE = 15 * 1024;

setLogLevel("debug");

export const getLocalPeerId = () => {
  return mixpanel.get_distinct_id() as string;
};

type WebRTCContextType = {
  sendMessageInChunks: (
    eventName: string,
    message: Uint8Array,
    reliable?: boolean
  ) => void;
  eventEmitter: EventEmitter;
  isLoadingLocalUserMedia: boolean;
  setIsLoadingLocalUserMedia: (isLoading: boolean) => void;
};

const WebRTCContext = createContext<WebRTCContextType | undefined>(undefined);

/**
 * Custom hook to access the WebRTC context.
 * This hook should not be used directly by our child components.
 * Instead, we should create a hook to export the specific functionality we need.
 *
 * @returns {WebRTCContextType} The current WebRTC context value.
 * @throws {Error} If the hook is used outside of a `WebRTCContextProvider`.
 */
const useWebRTCContext = () => {
  const context = useContext(WebRTCContext);
  if (!context) {
    throw new Error(
      "useWebRTCContext must be used within a WebRTCContextProvider"
    );
  }
  return context;
};

export const WebRTCContextProvider = ({
  children,
}: WebRTCContextProviderProps) => {
  const eventEmitterRef = useRef(new EventEmitter());
  const { sendMessageInChunks } =
    useLiveKitDataChannelMessageHandler(eventEmitterRef);
  const [isLoadingLocalUserMedia, setIsLoadingLocalUserMedia] = useState(false);

  const contextValue = useMemo<WebRTCContextType>(
    () => ({
      sendMessageInChunks,
      eventEmitter: eventEmitterRef.current,
      isLoadingLocalUserMedia,
      setIsLoadingLocalUserMedia,
    }),
    [sendMessageInChunks, isLoadingLocalUserMedia]
  );
  return (
    <WebRTCContext.Provider value={contextValue}>
      {children}
    </WebRTCContext.Provider>
  );
};

export const WebRTCContextProviderWrapper = ({
  children,
}: WebRTCContextProviderProps) => {
  const livekitToken = useLivekitToken();

  const handleLiveKitDisconnect = useCallback((reason?: DisconnectReason) => {
    if (reason !== DisconnectReason.CLIENT_INITIATED) {
      logUnexpectedError(`Livekit disconnected: ${reason}`);
    }
  }, []);

  const handleMediaDeviceFailure = useCallback(
    (failure?: MediaDeviceFailure) => {
      if (failure) {
        logUnexpectedError(`Media device failure: ${failure}`);
      }
    },
    []
  );

  const handleLiveKitError = useCallback((error: Error) => {
    logUnexpectedError(`Livekit error: ${error}`);
  }, []);

  return (
    <LiveKitRoom
      key={livekitToken}
      serverUrl={serverUrl}
      token={livekitToken}
      style={{ height: "100%", margin: 0 }}
      onDisconnected={handleLiveKitDisconnect}
      onMediaDeviceFailure={handleMediaDeviceFailure}
      onError={handleLiveKitError}
      options={{
        videoCaptureDefaults: { resolution: INITIAL_VIDEO_PRESET.resolution },
        adaptiveStream: true,
        dynacast: true,
        publishDefaults: {
          backupCodec: true,
          videoCodec: "vp9",
        },
      }}
    >
      <WebRTCContextProvider>{children}</WebRTCContextProvider>
    </LiveKitRoom>
  );
};

/**
 * Custom hook to handle LiveKit data channel messages.
 *
 * This hook manages the reception and assembly of chunked messages sent over a LiveKit data channel.
 * It listens for incoming messages, reconstructs them if they are sent in chunks, and emits the complete
 * message using the provided event emitter reference.
 *
 * This hook is intended to be used only once. It will listen to ALL livekit data channel messages, and emit
 * an event to the emitterRef when a message is fully received.
 *
 * Also this hook declares the `sendMessageInChunks` function, which should be stored in a context
 * and exposed to other components that need to send messages over the data channel.
 *
 * If the message was not split into multiple parts, the only overhead is copying the message into a new Uint8Array.
 * If we want to improve performance, we can check if `totalChunks === 1` and implement a fast path for this case.
 *
 * Also, for performance improvements, see the `stream` option in [TextDecoder.decode()](https://developer.mozilla.org/en-US/docs/Web/API/TextDecoder/decode).
 * Another possible improvement is to use [TextEncoder.encodeInto()](https://developer.mozilla.org/en-US/docs/Web/API/TextEncoder/encodeInto)
 *
 * @param {React.MutableRefObject<EventEmitter>} emitterRef - A reference to an EventEmitter instance used to emit events.
 * @return {Object} An object containing the `sendMessageInChunks` function.
 *
 * @typedef {Object} WebRTCContextType
 * @property {Function} sendMessageInChunks - Function to send a message in chunks over the data channel.
 *
 * @callback sendMessageInChunks
 * @param {string} eventName - The name of the event to emit. It must not contain the `|` character.
 * @param {Uint8Array} message - The message to send, which will be split into chunks if necessary. Use `new TextEncoder().encode()` to convert a string to a Uint8Array.
 * @param {boolean} reliable - Whether the message should be sent reliably.
 */
const useLiveKitDataChannelMessageHandler = (
  emitterRef: React.MutableRefObject<EventEmitter>
) => {
  const dataChannelMessagesPartsRef = useRef<{ [key: string]: Uint8Array[] }>(
    {}
  );

  const livekitRoom = useMaybeRoomContext();

  const dataChannelListener = useCallback((message: ReceivedDataMessage) => {
    // Don't rely on the from property, as it may be undefined
    // if messages are received before the participant is known
    const { payload, topic } = message;
    console.log("received datachannel message", message);

    if (!topic) {
      logUnexpectedError(
        "Received datachannel message without topic. ignoring"
      );
      return;
    }

    const eventName = topic.split("|")[0];
    const messageId = topic.split("|")[1];
    const chunkIndex = parseInt(topic.split("|")[2], 10);
    const totalChunks = parseInt(topic.split("|")[3], 10);

    const messageParts = dataChannelMessagesPartsRef.current[messageId] || [];
    messageParts[chunkIndex] = payload;
    dataChannelMessagesPartsRef.current[messageId] = messageParts;

    // Handle the complete message
    if (
      messageParts.length === totalChunks &&
      messageParts.every((part) => part)
    ) {
      const completeMessage = new Uint8Array(
        messageParts.reduce((acc, part) => acc + part.length, 0)
      );
      let offset = 0;
      messageParts.forEach((part) => {
        completeMessage.set(part, offset);
        offset += part.length;
      });

      const messageString = new TextDecoder().decode(completeMessage);
      const messageObject = JSON.parse(messageString);

      console.log(
        `emitting received event. had ${messageParts.length} parts`,
        eventName,
        messageObject
      );
      emitterRef.current.emit(eventName, { payload: messageObject });

      // Clear the stored parts
      delete dataChannelMessagesPartsRef.current[messageId];
    }
  }, []);

  const { send } = useDataChannel(dataChannelListener);

  const sendMessageInChunks: WebRTCContextType["sendMessageInChunks"] =
    useCallback(
      (eventName, message, reliable) => {
        if (livekitRoom?.state !== "connected") {
          console.log("ignoring event emit while not connected", eventName);
          return;
        }

        const totalChunks = Math.ceil(message.length / MAX_MESSAGE_SIZE);
        const messageId = uuidv4();
        for (let i = 0; i < message.length; i += MAX_MESSAGE_SIZE) {
          const chunk = message.slice(i, i + MAX_MESSAGE_SIZE);
          const currentIndex = i / MAX_MESSAGE_SIZE;
          const chunkTopic = [
            eventName,
            messageId,
            currentIndex,
            totalChunks,
          ].join("|");

          // The actual send function returns a promise,
          // but the React wrapper typing says it returns void.
          // This is a workaround until livekit team fixes this typing issue.
          const promiseSend = send as (
            payload: Uint8Array,
            options: DataPublishOptions
          ) => Promise<void>;
          console.log(
            "sending datachannel message",
            eventName,
            livekitRoom.localParticipant.identity
          );

          promiseSend(chunk, { topic: chunkTopic, reliable }).catch(
            logUnexpectedError
          );
        }
      },
      [livekitRoom, send]
    );

  return { sendMessageInChunks };
};

/**
 * This is the hook to be used by components throughout the app to send and receive events over the data channel.
 * This hook connects the listener function to our custom event emitter, and exposes a function to send events.
 * For the typed wrapper of this hook, see `useTeleoEvent`. All components should use `useTeleoEvent` instead of this hook.
 *
 * @param {string} eventName - The name of the event to listen for. It must not contain the `|` character.
 * @param {Function} [listener] - Optional listener function to handle the event. The listener receives the event payload and the participant who sent the event.
 * @return {Function} - A function to send events with the specified event name and data.
 *
 * @example
 * const sendEvent = useWebRTCEvent('myEvent', (payload, from) => {
 *   console.log('Received event from', from?.identity, 'with payload', payload);
 * });
 *
 * sendEvent('myEvent', { key: 'value' }, true);
 */
export const useWebRTCEvent = (
  eventName: string,
  listener?: (...args: any[]) => void
) => {
  const { sendMessageInChunks, eventEmitter } = useWebRTCContext();

  useEffect(() => {
    if (!listener) {
      return;
    }
    const handleEvent = ({ payload }: { payload: any }) => {
      listener(payload);
    };
    eventEmitter.addListener(eventName, handleEvent);
    return () => {
      eventEmitter.removeListener(eventName, handleEvent);
    };
  }, [listener]);

  const sendEvent = useCallback<WebRTCContextType["sendMessageInChunks"]>(
    (eventName: string, data: any, reliable?: boolean) => {
      const payloadString = JSON.stringify(data);
      const encoder = new TextEncoder();
      const byteArray = encoder.encode(payloadString);
      sendMessageInChunks(eventName, byteArray, reliable);
    },
    [sendMessageInChunks]
  );
  return sendEvent;
};

export const useIsWebRTRCConnecting = () => {
  const connectionState = useConnectionState();
  return ["connecting", "reconnecting", "signalReconnecting"].includes(
    connectionState
  );
};

export const useIsWebRTCConnected = () => {
  const connectionState = useConnectionState();
  console.log("roomstate", connectionState);
  return connectionState === "connected";
};

export const useIsWebRTCDisconnected = () => {
  const connectionState = useConnectionState();
  return connectionState === "disconnected";
};

export const useBrowserSandboxTracks = () => {
  const tracks = useParticipantTracks(
    [
      Track.Source.Camera,
      Track.Source.Microphone,
      Track.Source.ScreenShare,
      Track.Source.ScreenShareAudio,
      Track.Source.Unknown,
    ],
    ROOM_LIVEKIT_PARTICIPANT_ID
  );
  console.log("tracks", tracks);
  const videoTrack = tracks.find((track) => track.publication.kind === "video");
  const audioTrack = tracks.find((track) => track.publication.kind === "audio");
  return { videoTrack, audioTrack };
};

export const usePeerTracks = (participantId: string | undefined) => {
  const tracks = useParticipantTracks(
    [Track.Source.Camera, Track.Source.Microphone],
    participantId
  );
  const videoTrack = tracks.find((track) => track.publication.kind === "video");
  const audioTrack = tracks.find((track) => track.publication.kind === "audio");
  console.log("peer tracks", videoTrack, audioTrack, participantId);
  return participantId
    ? { videoTrack, audioTrack }
    : { videoTrack: undefined, audioTrack: undefined };
};

export const usePeers = (): TeleoPeer[] => {
  const remoteParticipants = useRemoteParticipants();
  return remoteParticipants.filter(
    (participant) => participant.identity !== ROOM_LIVEKIT_PARTICIPANT_ID
  );
};

export const useSortedParticipants = (): TeleoPeer[] => {
  const peers = usePeers();
  const localParticipant = useLocalParticipant();
  return [...peers, localParticipant.localParticipant]
    .sort((a, b) => {
      const peerARole = a.attributes.role;
      const peerBRole = b.attributes.role;
      if (peerARole === "provider") {
        return 1;
      }
      if (peerBRole === "provider") {
        return -1;
      }

      return a.identity > b.identity ? 1 : -1;
    })
    .reverse();
};

export const useOnParticipantConnect = (
  listener: (participant: Participant) => void
) => {
  const livekitRoom = useMaybeRoomContext();
  useEffect(() => {
    if (!livekitRoom) {
      return;
    }
    // Filter out the room-gst-producer participant
    const participantConnectedListener = (participant: RemoteParticipant) => {
      console.log("onParticipantConnect", participant);
      if (participant.identity !== ROOM_LIVEKIT_PARTICIPANT_ID) {
        listener(participant);
      }
    };
    livekitRoom.on("participantConnected", participantConnectedListener);
    return () => {
      livekitRoom.off("participantConnected", participantConnectedListener);
    };
  }, [listener, livekitRoom]);
};

export const useOnParticipantDisconnect = (
  listener: (
    participant: Participant,
    remainingParticipants: RemoteParticipant[]
  ) => void
) => {
  const livekitRoom = useMaybeRoomContext();
  useEffect(() => {
    if (!livekitRoom) {
      return;
    }
    // Filter out the room-gst-producer participant
    const participantDisconnectedListener = (
      participant: RemoteParticipant
    ) => {
      console.log("onParticipantDisconnect", participant);
      if (participant.identity !== ROOM_LIVEKIT_PARTICIPANT_ID) {
        listener(participant, [...livekitRoom.remoteParticipants.values()]);
      }
    };
    livekitRoom.on("participantDisconnected", participantDisconnectedListener);
    return () => {
      livekitRoom.off(
        "participantDisconnected",
        participantDisconnectedListener
      );
    };
  }, [listener, livekitRoom]);
};

export const useOnWebRTCConnect = (
  onConnect: (participants: RemoteParticipant[]) => void
) => {
  const livekitRoom = useMaybeRoomContext();
  useEffect(() => {
    if (!livekitRoom) {
      return;
    }
    const connectedListener = () => {
      onConnect([...livekitRoom.remoteParticipants.values()]);
    };
    livekitRoom.on("connected", connectedListener);
    return () => {
      livekitRoom.off("connected", connectedListener);
    };
  }, [onConnect, livekitRoom]);
};

export const useLocalUserConnectionQuality = () => {
  const localParticipant = useLocalParticipant();
  const { quality: connectionQuality } = useConnectionQualityIndicator({
    participant: localParticipant.localParticipant,
  });
  return useMemo(() => connectionQuality, [connectionQuality]);
};

/**
 * Custom hook that provides an error message based on the last camera or microphone error.
 * As soon as the error is resolved, the error message will be cleared.
 *
 * @return {string | undefined} A user-friendly error message if there is an error with the local user's media devices, or `undefined` if there are no errors.
 */
export const useLocalUserMediaError = () => {
  const { lastCameraError, lastMicrophoneError } = useLocalParticipant();
  if (lastCameraError) {
    console.log(lastCameraError);
  }
  return useMemo(() => {
    const error = lastCameraError || lastMicrophoneError;
    if (error) {
      if (error.name === "NotAllowedError") {
        return "Camera or microphone permission denied";
      }
      if (
        error.name === "NotFoundError" ||
        error.name === "OverconstrainedError"
      ) {
        return "Could not find camera or microphone";
      }
      if (error.name === "NotReadableError") {
        return "Camera is busy with another app. Please close it and retry";
      }
      return "An unexpected error occurred";
    }
    return undefined;
  }, [lastMicrophoneError, lastCameraError]);
};

export const useIsLoadingLocalUserMedia = () => {
  const { isLoadingLocalUserMedia } = useWebRTCContext();
  return isLoadingLocalUserMedia;
};

export const useSetIsLoadingLocalUserMedia = () => {
  const { setIsLoadingLocalUserMedia } = useWebRTCContext();
  return setIsLoadingLocalUserMedia;
};
