import { store } from "redux/reduxStore";
import {
  selectEncodedAuthToken,
  selectEncodedClientToken,
} from "redux/userRedux";

type BackendRequestParams = {
  path: string;
  searchParams?: { [key: string]: string };
  options?: RequestInit;
};

export const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export const backendRequest = async ({
  path,
  searchParams,
  options,
}: BackendRequestParams) => {
  const storeState = store.getState();
  const encodedProviderToken = selectEncodedAuthToken(storeState);
  const encodedClientToken = selectEncodedClientToken(storeState);
  const encodedToken = encodedProviderToken ?? encodedClientToken ?? "";
  const queryParams = new URLSearchParams();
  if (encodedToken) {
    queryParams.set("token", encodedToken);
  }
  for (const [key, value] of Object.entries(searchParams || {})) {
    if (value) {
      queryParams.set(key, value);
    }
  }

  const host = process.env.REACT_APP_SIGNALING_SERVER;
  if (!host || !host.startsWith("https://")) {
    throw new Error(
      "REACT_APP_SIGNALING_SERVER must be set and start with https://"
    );
  }

  if (!path || !path.startsWith("/")) {
    throw new Error("Path must be set and start with /");
  }

  return await fetch(`${host}${path}?${queryParams}`, {
    credentials: "include",
    ...options,
  });
};
