import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { useSelector } from "react-redux";

import {
  selectOrganizationId,
  selectUserId,
  selectUserJoinTimestamp,
} from "redux/userRedux";
import { backendRequest } from "utils/backendRequest";
import { logUnexpectedError } from "utils/errorUtils";
import { useGetSubscriptionMetricUsageQuery } from "generated/graphql";

export const LOW_USAGE_PERCENT_THRESHOLD = 80;
const JOIN_DATE_ENFORCE_USAGE_LIMITS_THRESHOLD = 1739232000000; // Feb 11, 2025 at midnight UTC

type PlanID = "FREE_TRIAL" | "FREE" | "PLUS" | "UNLIMITED";

type Plan = {
  name: string;
  maxSessionCount?: number;
  maxClientCount?: number;
  maxCustomActivityCount?: number;
};

type MetricUsage = {
  sessionCount: number | undefined;
  clientCount: number | undefined;
  customActivityCount: number | undefined;
};

const PLAN_MAP: Record<PlanID, Plan> = {
  FREE_TRIAL: {
    name: "Unlimited",
    maxSessionCount: 10,
  },
  FREE: {
    name: "Free",
    maxSessionCount: 5,
    maxClientCount: 10,
    maxCustomActivityCount: 10,
  },
  PLUS: {
    name: "Plus",
    maxSessionCount: 15,
    maxCustomActivityCount: 50,
  },
  UNLIMITED: {
    name: "Unlimited",
  },
};

type SubscriptionContextProviderProps = {
  children: ReactNode;
};

type SubscriptionContextType = {
  planId: PlanID | undefined;
  plan: Plan | undefined;
  stripePlanId: String | undefined;
  stripePlan: Plan | undefined;
  metricUsage: MetricUsage;
  lowUsageMetrics: string[];
  maxUsageMetrics: string[];
  currentPeriodEnd: number | undefined;
  shouldShowPaywall: boolean;
  shouldShowPaywallWarning: boolean;
  shouldEnforceUsageLimits: boolean;
  fetchSubscription: () => void;
  showClientPaywallIfNecessary: () => boolean;
  showSessionPaywallIfNecessary: () => boolean;
  showCustomActivityPaywallIfNecessary: () => boolean;
  hidePaywall: () => void;
};

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(
  undefined
);
const isLowOnUsage = (current: number | undefined, max: number | undefined) =>
  current &&
  max &&
  Math.ceil((current / max) * 100) >= LOW_USAGE_PERCENT_THRESHOLD &&
  current < max;

const hasReachedMaxUsage = (
  current: number | undefined,
  max: number | undefined
) => {
  return current && max && current >= max;
};

/**
 * Custom hook to access the subscription context.
 *
 * @returns {SubscriptionContextType} The current subscription context value.
 * @throws {Error} If the hook is used outside of a `SubscriptionContextProvider`.
 */
export const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error(
      "useSubscriptionContext must be used within a SubscriptionContextProvider"
    );
  }
  return context;
};

export const SubscriptionContextProvider = ({
  children,
}: SubscriptionContextProviderProps) => {
  const userId = useSelector(selectUserId);
  const organizationId = useSelector(selectOrganizationId);
  const userJoinTimestamp = useSelector(selectUserJoinTimestamp);

  const [planId, setPlanId] = useState<PlanID | undefined>();
  const [stripePlanId, setStripePlanId] = useState<PlanID | undefined>();
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState<
    number | undefined
  >();
  const [shouldShowPaywall, setShouldShowPaywall] = useState(false);
  const [shouldShowPaywallWarning, setShouldShowPaywallWarning] =
    useState(false);

  const periodStartTimestamp = useMemo(() => {
    if (!currentPeriodEnd || planId === "FREE_TRIAL") {
      return undefined;
    }
    const previousPeriodEndDate = new Date(currentPeriodEnd * 1000);
    previousPeriodEndDate.setMonth(previousPeriodEndDate.getMonth() - 1);
    return previousPeriodEndDate.toISOString();
  }, [currentPeriodEnd]);

  const shouldEnforceUsageLimits = useMemo(() => {
    if (!userJoinTimestamp) {
      return true;
    }
    return (
      new Date(userJoinTimestamp).getTime() >
      JOIN_DATE_ENFORCE_USAGE_LIMITS_THRESHOLD
    );
  }, [userJoinTimestamp]);

  const { data: subscriptionMetricUsage, previousData } =
    useGetSubscriptionMetricUsageQuery({
      variables: {
        userId: userId!,
        periodTimestamp: periodStartTimestamp,
      },
      skip: !userId || !planId,
    });

  const fetchSubscription = useCallback(async () => {
    // If the user has not joined yet, we don't need to fetch subscription data
    if (!userId || !userJoinTimestamp || organizationId) {
      return;
    }

    try {
      const subscriptionResponse = await backendRequest({
        path: "/billing-subscription",
        options: { method: "POST" },
      });

      if (!subscriptionResponse.ok) {
        logUnexpectedError(
          `Billing subscription endpoint responded with non-OK status: ${subscriptionResponse.status}`
        );
        return;
      }

      if (subscriptionResponse.status === 204) {
        // No subscription data found for this account
        return;
      }

      const subscriptionData: {
        planId: PlanID;
        stripePlanId: PlanID;
        currentPeriodEnd: number;
      } = await subscriptionResponse.json();

      setCurrentPeriodEnd(subscriptionData.currentPeriodEnd);
      setPlanId(subscriptionData.planId);
      setStripePlanId(subscriptionData.stripePlanId);
    } catch (error) {
      logUnexpectedError(error);
    }
  }, [userId, userJoinTimestamp, organizationId]);

  useEffect(() => {
    fetchSubscription();

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        fetchSubscription();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [fetchSubscription]);

  const sessionCount =
    subscriptionMetricUsage?.user_session_aggregate.aggregate?.count;
  const clientCount =
    subscriptionMetricUsage?.user_by_pk?.clients_aggregate.aggregate?.count;
  const customActivityCount =
    subscriptionMetricUsage?.user_by_pk?.resources_aggregate.aggregate?.count;

  const plan = planId ? PLAN_MAP[planId] : undefined;
  const stripePlan = stripePlanId ? PLAN_MAP[stripePlanId] : undefined;
  const maxSessionCount = plan?.maxSessionCount;
  const maxClientCount = plan?.maxClientCount;
  const maxCustomActivityCount = plan?.maxCustomActivityCount;

  const usageDependencyList = [
    sessionCount,
    customActivityCount,
    clientCount,
    maxSessionCount,
    maxCustomActivityCount,
    maxClientCount,
  ];

  const lowUsageMetrics = useMemo(() => {
    const metrics = [];
    if (isLowOnUsage(sessionCount, maxSessionCount)) {
      metrics.push("Sessions");
    }
    if (isLowOnUsage(customActivityCount, maxCustomActivityCount)) {
      metrics.push("Custom activities");
    }
    if (isLowOnUsage(clientCount, maxClientCount)) {
      metrics.push("Client records");
    }
    return metrics;
  }, usageDependencyList);

  const maxUsageMetrics = useMemo(() => {
    const metrics = [];
    if (hasReachedMaxUsage(sessionCount, maxSessionCount)) {
      metrics.push("Sessions");
    }
    if (hasReachedMaxUsage(customActivityCount, maxCustomActivityCount)) {
      metrics.push("Custom activities");
    }
    if (hasReachedMaxUsage(clientCount, maxClientCount)) {
      metrics.push("Client records");
    }
    return metrics;
  }, usageDependencyList);

  const showPaywallIfNecessary = (
    metricCount?: number,
    maxMetricCount?: number
  ) => {
    if (!shouldEnforceUsageLimits) {
      return false;
    }
    const showMetricPaywall = !!hasReachedMaxUsage(metricCount, maxMetricCount);
    setShouldShowPaywall(showMetricPaywall);
    return showMetricPaywall;
  };

  useEffect(() => {
    if (!shouldShowPaywallWarning) {
      return;
    }
    const timer = setTimeout(() => setShouldShowPaywallWarning(false), 5000);
    return () => clearTimeout(timer);
  }, [shouldShowPaywallWarning]);

  const hidePaywall = useCallback(() => setShouldShowPaywall(false), []);

  const showClientPaywallIfNecessary = useCallback(
    () => showPaywallIfNecessary(clientCount, maxClientCount),
    [clientCount, maxClientCount]
  );

  const showSessionPaywallIfNecessary = useCallback(
    () => showPaywallIfNecessary(sessionCount, maxSessionCount),
    [sessionCount, maxSessionCount]
  );

  const showCustomActivityPaywallIfNecessary = useCallback(
    () => showPaywallIfNecessary(customActivityCount, maxCustomActivityCount),
    [customActivityCount, maxCustomActivityCount]
  );

  useEffect(() => {
    if (!previousData || !subscriptionMetricUsage) {
      return;
    }

    const checks = [
      {
        current: sessionCount,
        previous: previousData?.user_session_aggregate.aggregate?.count,
        max: maxSessionCount,
      },
      {
        current: clientCount,
        previous: previousData?.user_by_pk?.clients_aggregate.aggregate?.count,
        max: maxClientCount,
      },
      {
        current: customActivityCount,
        previous:
          previousData?.user_by_pk?.resources_aggregate.aggregate?.count,
        max: maxCustomActivityCount,
      },
    ];

    const shouldWarn = checks.some(
      ({ current, previous, max }) =>
        current !== undefined &&
        previous !== undefined &&
        current > previous &&
        (isLowOnUsage(current, max) || hasReachedMaxUsage(current, max))
    );

    if (shouldWarn) {
      setShouldShowPaywallWarning(true);
    }
  }, [
    previousData,
    subscriptionMetricUsage,
    maxSessionCount,
    maxClientCount,
    maxCustomActivityCount,
  ]);

  const contextValue = useMemo(
    () => ({
      planId,
      plan,
      stripePlanId,
      stripePlan,
      metricUsage: {
        sessionCount,
        clientCount,
        customActivityCount,
      },
      lowUsageMetrics,
      maxUsageMetrics,
      currentPeriodEnd,
      shouldShowPaywall,
      shouldShowPaywallWarning,
      shouldEnforceUsageLimits,
      fetchSubscription,
      showClientPaywallIfNecessary,
      showSessionPaywallIfNecessary,
      showCustomActivityPaywallIfNecessary,
      hidePaywall,
    }),
    [
      planId,
      stripePlanId,
      sessionCount,
      clientCount,
      customActivityCount,
      lowUsageMetrics,
      maxUsageMetrics,
      currentPeriodEnd,
      shouldShowPaywall,
      shouldShowPaywallWarning,
      shouldEnforceUsageLimits,
      fetchSubscription,
      showClientPaywallIfNecessary,
      showSessionPaywallIfNecessary,
      showCustomActivityPaywallIfNecessary,
      hidePaywall,
    ]
  );
  return (
    <SubscriptionContext.Provider value={contextValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};
