import React from "react";
import { useDispatch } from "react-redux";

import styles from "./WaitingRoomAlert.module.css";
import { removeWaitingRoomAlertClient } from "redux/clientManagementRedux";
import { useWaitingRoomAdmitHandler } from "utils/waitingRoomUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { Alert } from "./Alert";
import { useTrackEventWithShowHide } from "utils/metricsUtils";
import { useSubscriptionContext } from "components/SubscriptionContext/SubsciptionContext";

type WaitingRoomAlertProps = {
  secretClientId: string;
  name: string;
};

export const WaitingRoomAlert = ({
  secretClientId,
  name,
}: WaitingRoomAlertProps) => {
  const dispatch = useDispatch();

  const { showSessionPaywallIfNecessary } = useSubscriptionContext();

  const { trackEvent } = useTrackEventWithShowHide("Waiting room alert banner");

  const dismiss = () => {
    // Remove from alert list (but keep in waiting room menu)
    dispatch(removeWaitingRoomAlertClient(secretClientId));
    trackEvent("Waiting room alert banner - dismiss clicked");
  };

  const admit = useWaitingRoomAdmitHandler({ secretClientId, name });

  const admitClickHandler = async () => {
    if (showSessionPaywallIfNecessary()) {
      return;
    }
    try {
      trackEvent(`Waiting room alert banner - admit clicked`);
      await admit();
      dispatch(removeWaitingRoomAlertClient(secretClientId));
    } catch (e) {
      logUnexpectedError(e);
    }
  };

  return (
    <Alert
      actionCTAText="Admit"
      actionHandler={admitClickHandler}
      dismissHandler={dismiss}
    >
      <div className={styles.nameText}>{name}</div>
      <div className={styles.text}>{"has entered your waiting room."}</div>
    </Alert>
  );
};
