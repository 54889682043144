import {
  useLocalParticipant,
  useMediaDeviceSelect,
  useRoomContext,
} from "@livekit/components-react";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  VideoPresets,
  VideoPresets43,
} from "livekit-client";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsMuted,
  selectIsNoiseSuppressionEnabled,
  selectIsVideoBlurred,
  selectIsVideoOff,
  selectIsWidescreenVideoEnabled,
  selectSelectedAudioDeviceId,
  selectSelectedVideoDeviceId,
  selectVideoConferencing,
  setIsVideoBlurred,
  setSelectedAudioDeviceId,
  setSelectedVideoDeviceId,
} from "redux/settingsRedux";
import {
  useIsWebRTCConnected,
  useSetIsLoadingLocalUserMedia,
} from "./WebRTCContext";
import { logUnexpectedError } from "utils/errorUtils";
import TeleoBackgroundBlurProcessor from "pages/Space/hooks/video/utils/TeleoBackgroundBlurProcessor";
import { useIsTherapist } from "pages/Space/hooks/useIsTherapist";
import { useSetVideoBlurMutation } from "generated/graphql";
import { selectUserId } from "redux/userRedux";
import { isMobileDevice } from "utils/deviceUtils";

/* eslint new-cap: 0 */

export const INITIAL_VIDEO_PRESET = VideoPresets43.h1080;

/**
 * Custom hook to get what video preset should be used.
 *
 * @return {VideoPreset} The video preset based on whether widescreen video is enabled.
 */
export const useVideoPreset = () => {
  const isWidescreenVideoEnabled = useSelector(selectIsWidescreenVideoEnabled);
  return useMemo(
    () =>
      isWidescreenVideoEnabled ? VideoPresets.h1080 : VideoPresets43.h1080,
    [isWidescreenVideoEnabled]
  );
};

/**
 * Custom hook to reload the local user's media (camera and microphone).
 * This will trigger the browser permission modal again if needed.
 *
 * @returns {Function} A function to reload the local user's media.
 *
 * @example
 * const reloadLocalUserMedia = useReloadLocalUserMedia();
 *
 * // Call the function to reload the media
 * reloadLocalUserMedia();
 *
 */
export const useReloadLocalUserMedia = () => {
  const { localParticipant } = useLocalParticipant();
  const isMuted = useSelector(selectIsMuted);
  const isLocalVideoOff = useSelector(selectIsVideoOff);
  const setIsLoadingLocalUserMedia = useSetIsLoadingLocalUserMedia();

  const reloadLocalUserMedia = async () => {
    try {
      const promises: Promise<any>[] = [];
      if (isLocalVideoOff !== true) {
        console.log("setting loading true");
        setIsLoadingLocalUserMedia(true);
        promises.push(
          localParticipant.setCameraEnabled(true).catch((e) => {
            // bugfix until https://github.com/livekit/client-sdk-js/pull/1380 is released
            // @ts-ignore
            localParticipant.pendingPublishing?.delete("camera");
            throw e;
          })
        );
      }

      if (isMuted !== true) {
        console.log("setting loading true");
        setIsLoadingLocalUserMedia(true);
        promises.push(
          localParticipant.setMicrophoneEnabled(true).catch((e) => {
            // bugfix until https://github.com/livekit/client-sdk-js/pull/1380 is released
            // @ts-ignore
            localParticipant.pendingPublishing?.delete("microphone");
            throw e;
          })
        );
      }

      await Promise.all(promises);
    } catch (error) {
      logUnexpectedError(error);
    } finally {
      console.log("setting loading false");
      setIsLoadingLocalUserMedia(false);
    }
  };

  return reloadLocalUserMedia;
};

/**
 * Custom hook to manage the selection of local user media devices (audio and video).
 *
 * @returns {Object} An object containing:
 * - `audioInputDevices`: Array of available audio input devices.
 * - `videoInputDevices`: Array of available video input devices.
 * - `activeAudioInputDeviceId`: The device ID of the currently active audio input device.
 * - `activeVideoInputDeviceId`: The device ID of the currently active video input device.
 * - `selectActiveAudioInputDevice`: Function to select and set the active audio input device.
 * - `selectActiveVideoInputDevice`: Function to select and set the active video input device.
 */
export const useLocalUserMediaSelection = () => {
  const dispatch = useDispatch();
  const setIsLoadingLocalUserMedia = useSetIsLoadingLocalUserMedia();
  const selectedAudioDeviceId = useSelector(selectSelectedAudioDeviceId);
  const selectedVideoDeviceId = useSelector(selectSelectedVideoDeviceId);
  const {
    devices: audioInputDevices,
    setActiveMediaDevice: setActiveAudioInputDevice,
  } = useMediaDeviceSelect({ kind: "audioinput" });

  const {
    devices: videoInputDevices,
    setActiveMediaDevice: setActiveVideoInputDevice,
  } = useMediaDeviceSelect({ kind: "videoinput" });

  const selectActiveAudioInputDevice = async (device: MediaDeviceInfo) => {
    dispatch(setSelectedAudioDeviceId(device.deviceId));
    try {
      setIsLoadingLocalUserMedia(true);
      await setActiveAudioInputDevice(device.deviceId);
    } catch (error) {
      logUnexpectedError(error);
    } finally {
      setIsLoadingLocalUserMedia(false);
    }
  };

  const selectActiveVideoInputDevice = async (device: MediaDeviceInfo) => {
    dispatch(setSelectedVideoDeviceId(device.deviceId));
    try {
      setIsLoadingLocalUserMedia(true);
      await setActiveVideoInputDevice(device.deviceId);
    } catch (error) {
      logUnexpectedError(error);
    } finally {
      setIsLoadingLocalUserMedia(false);
    }
  };

  return {
    audioInputDevices,
    videoInputDevices,
    activeAudioInputDeviceId: selectedAudioDeviceId,
    activeVideoInputDeviceId: selectedVideoDeviceId,
    selectActiveAudioInputDevice,
    selectActiveVideoInputDevice,
  };
};

const useBackgroundBlurringErrorHandler = () => {
  const isTherapist = useIsTherapist();
  const dispatch = useDispatch();
  const [setVideoBlurMutation] = useSetVideoBlurMutation();
  const userId = useSelector(selectUserId);

  const backgroundBlurringErrorHandler = (error: Error) => {
    logUnexpectedError(error);
    dispatch(setIsVideoBlurred(false));
    if (isTherapist) {
      setVideoBlurMutation({
        variables: {
          userId,
          videoBlur: false,
        },
      }).catch(logUnexpectedError);
    }
    alert("There was an error blurring the video background.");
  };
  return backgroundBlurringErrorHandler;
};

export const useLocalUserMedia = () => {
  const isWebRTCConnected = useIsWebRTCConnected();
  const room = useRoomContext();
  const {
    microphoneTrack: liveKitMicrophoneTrack,
    cameraTrack: liveKitCameraTrack,
    isCameraEnabled: isCameraEnabledInLiveKit,
    isMicrophoneEnabled: isMicrophoneEnabledInLiveKit,
    localParticipant,
  } = useLocalParticipant();

  const setIsLoadingLocalUserMedia = useSetIsLoadingLocalUserMedia();

  const isNoiseSuppressionEnabled = useSelector(
    selectIsNoiseSuppressionEnabled
  );
  const isMuted = useSelector(selectIsMuted);
  const isLocalVideoOff = useSelector(selectIsVideoOff);
  const isVideoConferencingEnabled = useSelector(selectVideoConferencing);
  const isBackgroundBlurringEnabled = useSelector(selectIsVideoBlurred);

  const backgroundBlurringErrorHandler = useBackgroundBlurringErrorHandler();

  const [blurProcessor] = useState(
    new TeleoBackgroundBlurProcessor(backgroundBlurringErrorHandler)
  );

  const desiredVideoPreset = useVideoPreset();

  useEffect(() => {
    if (!isWebRTCConnected) {
      return;
    }
    const audioTrack = liveKitMicrophoneTrack?.audioTrack;
    if (audioTrack && audioTrack instanceof LocalAudioTrack) {
      console.log(
        "audioTrack constraints",
        JSON.stringify(audioTrack.constraints)
      );

      let audioTrackChanged = false;
      if (
        isNoiseSuppressionEnabled !== undefined &&
        audioTrack.constraints.noiseSuppression !== isNoiseSuppressionEnabled
      ) {
        audioTrackChanged = true;
        audioTrack.constraints.noiseSuppression = isNoiseSuppressionEnabled;
      }

      if (audioTrackChanged) {
        console.log(
          "audioTrack constraints changed",
          JSON.stringify(audioTrack.constraints)
        );
        const restartTrackAsync = async () => {
          try {
            setIsLoadingLocalUserMedia(true);
            await audioTrack.restartTrack();
          } catch (error) {
            logUnexpectedError(error);
          } finally {
            setIsLoadingLocalUserMedia(false);
          }
        };
        restartTrackAsync();
      }
    }
  }, [liveKitMicrophoneTrack, isNoiseSuppressionEnabled, isWebRTCConnected]);

  useEffect(() => {
    if (!isWebRTCConnected) {
      return;
    }
    const videoTrack = liveKitCameraTrack?.videoTrack;
    if (
      isCameraEnabledInLiveKit &&
      videoTrack &&
      videoTrack instanceof LocalVideoTrack
    ) {
      console.log(
        "videoTrack constraints",
        JSON.stringify(videoTrack.constraints)
      );

      let videoTrackChanged = false;

      if (
        videoTrack.constraints.width !== desiredVideoPreset.width ||
        videoTrack.constraints.height !== desiredVideoPreset.height ||
        videoTrack.constraints.aspectRatio !== desiredVideoPreset.aspectRatio ||
        videoTrack.constraints.frameRate !==
          desiredVideoPreset.encoding.maxFramerate
      ) {
        videoTrackChanged = true;
        Object.assign(videoTrack.constraints, desiredVideoPreset);
      }

      if (videoTrackChanged) {
        console.log(
          "videoTrack constraints changed",
          JSON.stringify(videoTrack.constraints)
        );
        const restartTrackAsync = async () => {
          try {
            setIsLoadingLocalUserMedia(true);
            await videoTrack.restartTrack();
          } catch (error) {
            logUnexpectedError(error);
          } finally {
            setIsLoadingLocalUserMedia(false);
          }
        };
        restartTrackAsync();
      }
    }
  }, [
    liveKitCameraTrack,
    isCameraEnabledInLiveKit,
    desiredVideoPreset,
    isWebRTCConnected,
  ]);

  useEffect(() => {
    if (!isWebRTCConnected || isMobileDevice()) {
      return;
    }
    const videoTrack = liveKitCameraTrack?.videoTrack;

    if (
      isCameraEnabledInLiveKit &&
      videoTrack &&
      videoTrack instanceof LocalVideoTrack
    ) {
      const videoHasProcessor = !!videoTrack.getProcessor();

      // if need to enable the blur processor
      if (isBackgroundBlurringEnabled && !videoHasProcessor) {
        const setProcessorAsync = async () => {
          try {
            setIsLoadingLocalUserMedia(true);
            await videoTrack.setProcessor(blurProcessor);
          } catch (error: any) {
            backgroundBlurringErrorHandler(error);
          } finally {
            setIsLoadingLocalUserMedia(false);
          }
        };
        setProcessorAsync();
      }

      // if need to disable the blur processor
      if (!isBackgroundBlurringEnabled && videoHasProcessor) {
        const stopProcessorAsync = async () => {
          try {
            setIsLoadingLocalUserMedia(true);
            await videoTrack.stopProcessor();
          } catch (error) {
            logUnexpectedError(error);
          } finally {
            setIsLoadingLocalUserMedia(false);
          }
        };
        stopProcessorAsync();
      }
    }
  }, [liveKitCameraTrack, isBackgroundBlurringEnabled]);

  useEffect(() => {
    if (room.options.audioCaptureDefaults) {
      if (isNoiseSuppressionEnabled !== undefined) {
        console.log("setting audio capture defaults");
        room.options.audioCaptureDefaults.noiseSuppression =
          isNoiseSuppressionEnabled;
      }
    }
  }, [room, isNoiseSuppressionEnabled]);

  useEffect(() => {
    console.log(
      "update video capture defaults",
      room.options.videoCaptureDefaults
    );
    if (room.options.videoCaptureDefaults) {
      console.log("setting video capture defaults");
      room.options.videoCaptureDefaults.resolution =
        desiredVideoPreset.resolution;
    }
  }, [room, desiredVideoPreset]);

  const audioTrack = liveKitMicrophoneTrack?.audioTrack;

  useEffect(() => {
    if (!isWebRTCConnected) {
      return;
    }
    if (
      isMuted !== undefined &&
      audioTrack &&
      audioTrack instanceof LocalAudioTrack
    ) {
      if (isMuted) {
        if (!audioTrack.isMuted) {
          console.log("muting audio track");
          audioTrack.mute();
        }
      } else {
        if (audioTrack.isMuted) {
          console.log("unmuting audio track");
          audioTrack.unmute();
        }
      }
    }
  }, [audioTrack, isMuted, isWebRTCConnected]);

  useEffect(() => {
    if (!isWebRTCConnected) {
      return;
    }
    const asyncExecute = async () => {
      console.log("isVideoConferencingEnabled", isVideoConferencingEnabled);
      console.log(
        "livekitParticipant",
        isCameraEnabledInLiveKit,
        isMicrophoneEnabledInLiveKit
      );
      const promises: Promise<any>[] = [];
      try {
        if (!isVideoConferencingEnabled) {
          console.log("setting camera enabled to false");
          promises.push(localParticipant.setCameraEnabled(false));

          promises.push(localParticipant.setMicrophoneEnabled(false));

          await Promise.all(promises);

          return;
        }

        if (isCameraEnabledInLiveKit !== !isLocalVideoOff) {
          console.log("setting camera enabled to", !isLocalVideoOff);
          setIsLoadingLocalUserMedia(true);
          console.log("setting loading 2 true");
          promises.push(
            localParticipant.setCameraEnabled(!isLocalVideoOff).catch((e) => {
              // bugfix until https://github.com/livekit/client-sdk-js/pull/1380 is released
              // @ts-ignore
              localParticipant.pendingPublishing?.delete("camera");
              throw e;
            })
          );
        }

        if (!isMicrophoneEnabledInLiveKit && !isMuted) {
          setIsLoadingLocalUserMedia(true);
          promises.push(
            localParticipant.setMicrophoneEnabled(true).catch((e) => {
              // bugfix until https://github.com/livekit/client-sdk-js/pull/1380 is released
              // @ts-ignore
              localParticipant.pendingPublishing?.delete("microphone");
              throw e;
            })
          );
        }

        await Promise.all(promises);
      } catch (error) {
        logUnexpectedError(error);
      } finally {
        setIsLoadingLocalUserMedia(false);
      }
    };
    asyncExecute();
  }, [
    isCameraEnabledInLiveKit,
    isMicrophoneEnabledInLiveKit,
    localParticipant,
    isLocalVideoOff,
    isVideoConferencingEnabled,
    isWebRTCConnected,
    isMuted,
  ]);
};
