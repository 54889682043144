import {
  selectHasLaunchedActivityFromFullScreenVideo,
  setBrowserSandboxUrl,
  setCurrentPage,
  setCurrentResourceId,
  setCurrentRoomItemId,
  setHasLaunchedActivityFromFullScreenVideo,
  setIsFullScreenVideo,
  setShowRoomCustomizationActivityModal,
  setWhiteboardBackground,
  SpacePage,
} from "redux/spaceNavigationRedux";
import { useDispatch, useSelector } from "react-redux";
import { getAddBreadcrumb } from "utils/errorUtils";
import { useBoxRef } from "hooks/useBoxRef";
import { useTrackEvent } from "utils/metricsUtils";
import { useTeleoEvent } from "../components/ConnectionsContext/teleoPeerEventUtils";

const addBreadcrumb = getAddBreadcrumb("navigation");

// Listen for incoming "navigate" messages on the peer data channel, and update state correspondingly
export const useNavigateListener = () => {
  const dispatch = useDispatch();
  const { trackEvent } = useTrackEvent();
  const hasLaunchedActivityFromFullScreenVideo = useSelector(
    selectHasLaunchedActivityFromFullScreenVideo
  );
  const hasLaunchedActivityFromFullScreenVideoRef = useBoxRef(
    hasLaunchedActivityFromFullScreenVideo
  );

  useTeleoEvent("navigate", (payload) => {
    dispatch(setWhiteboardBackground(payload.whiteboardBackground));
    dispatch(setBrowserSandboxUrl(payload.browserSandboxUrl));
    dispatch(setCurrentPage(payload.currentPage));

    if (
      payload.currentPage === SpacePage.ROOM &&
      hasLaunchedActivityFromFullScreenVideoRef.current
    ) {
      dispatch(setIsFullScreenVideo(true));
      trackEvent("Video chat - talk mode opened", {
        ["source"]: "Peer navigated back to room",
      });
    } else {
      dispatch(setIsFullScreenVideo(false));
      trackEvent("Video chat - talk mode closed", {
        ["source"]: "Peer navigated to activity",
      });
    }

    dispatch(setCurrentResourceId(payload.resourceId));
    dispatch(setCurrentRoomItemId(payload.roomItemId));
    dispatch(
      setShowRoomCustomizationActivityModal(
        payload.showRoomCustomizationActivityModal
      )
    );
    dispatch(
      setHasLaunchedActivityFromFullScreenVideo(
        payload.isLaunchingFromFullScreenVideo
      )
    );

    addBreadcrumb("info", "peer navigated", {
      page: payload.currentPage,
    });
  });
};
