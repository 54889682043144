import React from "react";

import clsx from "clsx";
import styles from "./Toggle.module.css";

type ToggleProps = {
  isOn: boolean;
  text?: string;
  onToggle?: () => void;
  className?: string;
  disabled?: boolean;
};

const Toggle = ({ isOn, text, onToggle, className, disabled }: ToggleProps) => {
  const onClick = () => {
    if (disabled) {
      return;
    }
    onToggle?.();
  };

  return (
    <div
      className={clsx(
        styles.container,
        { [styles.active]: isOn, [styles.disabled]: disabled },
        className
      )}
      onClick={onClick}
    >
      <div className={styles.circle} />
      {text && <div className={styles.textContainer}>{text}</div>}
    </div>
  );
};

export default Toggle;
