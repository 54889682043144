import React from "react";
import styles from "./PlanTag.module.css";
import clsx from "clsx";

export type Plan = "FREE_TRIAL" | "FREE" | "PLUS" | "UNLIMITED";

type PlanTagProps = { planId: Plan; planName: string };

export const PlanTag = ({ planId, planName }: PlanTagProps) => (
  <div className={clsx(styles.container)}>
    {planId === "FREE_TRIAL" && (
      <div className={clsx(styles.trialBadge)}>Trial</div>
    )}
    <div
      className={clsx(styles.planTag, {
        [styles.free]: planId === "FREE",
        [styles.plus]: planId === "PLUS",
        [styles.unlimited]: planId === "UNLIMITED" || planId === "FREE_TRIAL",
      })}
    >
      {planName}
    </div>
  </div>
);
