import { Participant } from "livekit-client";
import { zoomLevelType } from "pages/Space/subpages/Whiteboard/whiteboardTypes";
import { SpacePage } from "redux/spaceNavigationRedux";
import { logUnexpectedError } from "utils/errorUtils";
import { Page, Snapshot } from "redux/clientAlbumRedux";
import { fabricTypes } from "utils/fabric-impl";
import { Client } from "redux/clientManagementRedux";
import { useWebRTCEvent } from "./WebRTCContext";

export type ViewportLockSetViewportLockedEventData =
  | {
      locked: true;
      scrollPosition: number;
      zoomLevel: zoomLevelType;
    }
  | {
      locked: false;
    };

/**
 * Events that can be sent between peers in a Teleo room. This is structured as: { eventName: payload }.
 * The payload must always be an object.
 *
 * To avoid issues with the large events strategy (see useLiveKitDataChannelMessageHandler),
 * we must not use `|` in event names.
 *
 * To mark an event as unreliable, add it to the NOT_RELIABLE_EVENTS array.
 */
export interface TeleoEvents {
  navigate: {
    currentPage: SpacePage;
    whiteboardBackground?: string;
    resourceId?: string;
    roomItemId?: string;
    browserSandboxUrl?: string;
    showRoomCustomizationActivityModal?: boolean;
    isLaunchingFromFullScreenVideo?: boolean;
  };
  userMediaError: { error: string | undefined; senderId: string };
  "is-playing-change": { isPlaying: boolean };
  "seek-to": { time: number };
  reset: {};
  "viewportLock.scroll": { position: number };
  "viewportLock.setZoomLevel": { zoomLevel: zoomLevelType };
  "viewportLock.setViewportLock": ViewportLockSetViewportLockedEventData;
  "room-items-changed": {};
  "is-editing": { isEditing: boolean };
  albumInitialize: {
    pages: Page[] | undefined;
    imgKeysToURL: { [key: string]: string };
    currentPage: number;
    viewingSnapshot?: Snapshot;
  };
  albumMoveToPage: { page: number };
  albumSetViewingSnapshot: { snapshot?: Snapshot };
  "text-added-or-modified": {
    id: string;
    text: string;
    options: fabricTypes.ITextOptions;
  };
  "object-added": any;
  "path-added": {
    obj: fabricTypes.Path;
    id: string;
  };
  "objects-modified": { obj: any; id: string }[];
  "eraser-path-mouse-down": { point: { x: number; y: number } };
  "path-mouse-down": { point: { x: number; y: number } };
  "path-mouse-move": { point: { x: number; y: number } };
  "path-mouse-up": { point: { x: number; y: number } };
  "brush-change": { color: string };
  "cursor-moved": { point: { x: number; y: number } };
  "cursor-out": {};
  "custom-color-changed": {
    colorId: string;
    color: string;
    position: number;
  };
  "room-customization-background-changed": {};
  "client-control": { clientCanControl: boolean };
  "room-changed": {};
  "audio-provider-only": { audioProviderOnly: boolean };
  "video-conferencing": { videoConferencing: boolean };
  "peer-video-off": {
    isPeerVideoOff: boolean;
    targetPeerId: string;
  };
  "peer-muted": {
    isPeerMuted: boolean;
    targetPeerId: string;
  };
  "peer-can-control-cursor": {
    targetPeerId: string;
  };
  "full-screen-video-conferencing": {
    isFullScreen: boolean;
  };
  "is-widescreen-video-enabled": {
    isWidescreenVideoEnabled: boolean;
  };
  "user-joined": { peerId: string };
  "sync-state": {
    senderId: string;
    currentClient: Client | undefined;
    clientCanControl: boolean;
    peerIdWithControl: string | undefined;
    audioProviderOnly: boolean | undefined;
    videoConferencing: boolean | undefined;
    isFullScreenVideo: boolean | undefined;
    isWidescreenVideoEnabled: boolean | undefined;
    hasLaunchedActivityFromFullScreenVideo: boolean | undefined;
    isMuted: boolean | undefined;
    isVideoOff: boolean | undefined;
    navigationState: {
      currentPage: SpacePage;
      currentRoomItemId: string | undefined;
      resourceId: string | undefined;
      whiteboardBackground: string | undefined;
      customColors: { [id: string]: string };
      browserSandboxUrl: string | undefined;
      showRoomCustomizationActivityModal: boolean | undefined;
    };
    currentAlbumPage: number;
    albumPages: Page[] | undefined;
    imgKeysToURL: Record<string, string>;
    viewingSnapshot: Snapshot | undefined;
    isViewportLocked: boolean;
    isProviderEditing: boolean;
  };
  "session-start": {
    isInternal: boolean;
  };
}

export type WebRTCEvent<Ev extends keyof TeleoEvents> = {
  from: Participant;
  topic: Ev;
  payload: TeleoEvents[Ev];
};

// The events listed here will be sent with LiveKit's reliable flag set to false.
// This means that they will be sent through the separated unreliable data channel.
const NOT_RELIABLE_EVENTS: (keyof TeleoEvents)[] = [
  "cursor-moved",
  "cursor-out",
  "path-mouse-move",
];

export const useTeleoEvent = <Ev extends keyof TeleoEvents>(
  eventName: Ev,
  listener?: (payload: TeleoEvents[Ev]) => void
) => {
  const sendWebRTCEvent = useWebRTCEvent(eventName, listener);

  const sendEvent = (data: TeleoEvents[Ev]) => {
    try {
      const isReliable = !NOT_RELIABLE_EVENTS.includes(eventName);
      sendWebRTCEvent(eventName, data, isReliable);
    } catch (error) {
      logUnexpectedError(error);
    }
  };
  return sendEvent;
};
